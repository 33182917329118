import Header from "components/Header/Header"
import React, { useEffect, useState } from "react"
import QuestionsBanks from "./components/QuestionBanks"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import AddToTrivia from "./AddTrivia"
import {
  getCategoryCountAction,
  getLikeDislikeListAction,
  getQuestionsBankAction
} from "redux/L&D/actions/user"
import { useDispatch, useSelector } from "react-redux"
import AppSelect from "components/StyledComponents/AppSelect"
import { Col, Row } from "react-bootstrap"
import { formatDate } from "helpers/functions"
import { appActions } from "redux/app/slice/app"
import { useDebounce } from "hooks/utils"
import { Box, Typography } from "@mui/material"
import styles from "../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import dislikeIcon from "assets/icons/thumbsDown.svg"
import likeIcon from "assets/icons/thumbsUp.svg"
import LikeDislikeDialog from "components/likeDislikeDialog"

const QuestionBank = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [openTriviaDialog, setOpenTriviaDialog] = useState(false)
  const [triviaData, setTriviaData] = useState({})
  const [filter, setFilter] = useState("ALL")
  const [filterQuestion, setFilterQuestion] = useState("ALL")
  const [month, setMonth] = useState(null)
  const { questionBank, likeDislikeData } = useSelector((state) => state?.learningDevelopment)
  const history = useHistory()
  const isIncorrectQuestionTrivia = history?.location?.state?.isIncorrect || false
  const rank = useSelector((store) => store?.app?.filters?.rank || [])
  const category = useSelector((store) => store?.app?.filters?.category || [])
  const { categoryCount } = useSelector((store) => store?.learningDevelopment || [])
  const [questionCategoryCount, setQuestionCategoryCount] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const [filteredResults, setFilteredResults] = useState([])
  const [questionList, setQuestionList] = useState([])
  const [page, setPage] = useState(1)
  const [openChoice, setOpenChoice] = useState(false)
  const [choice, setChoice] = useState("")
  const [isLike, setIsLike] = useState(true)
  const { userRestaurant } = useSelector((store) => store.user)

  const filters = [
    {
      label: "All",
      value: "ALL"
    },
    {
      label: userRestaurant?.name || "Scarpetta",
      value: userRestaurant?.name || "scarpetta"
    },
    {
      label: "Vantero AI",
      value: "gamification"
    }
  ]

  const handleAddQuestion = () => {
    history.push("/learning_development/dashboard/question-bank/add-questions")
  }

  const handleAddTriviaDialogClose = () => {
    setOpenTriviaDialog(false)
  }

  const handleAddToTrivia = (data) => {
    setOpenTriviaDialog(true)
    setTriviaData(data)
  }

  const handleFilter = (event) => {
    setFilter(event.target.value)
  }

  const handleChoice = (choice) => {
    if (choice === "like") {
      setChoice("like")
      setIsLike(true)
    } else {
      setChoice("dislike")
      setIsLike(false)
    }
    setOpenChoice(true)
  }

  const handleDialogClose = () => {
    setOpenChoice(false)
  }

  useEffect(() => {
    const start_date = month && formatDate(new Date(month.getFullYear(), month.getMonth(), 1))
    dispatch(
      getQuestionsBankAction({
        page: 1,
        platform: filter,
        question_used: filterQuestion,
        month: start_date
      })
    )
    setCurrentPage(1)
  }, [filter, filterQuestion, month])

  useEffect(() => {
    if (isIncorrectQuestionTrivia) setFilterQuestion("INCORRECT")
  }, [isIncorrectQuestionTrivia])

  function filterResults(result, selectedRank, selectedCategory, searchTerm) {
    let filteredResults = result

    // Check if search term is provided
    if (searchTerm.trim() !== "") {
      const searchRegex = new RegExp(searchTerm.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
      filteredResults = filteredResults.filter(
        (item) =>
          item.answer.some((answer) => searchRegex.test(answer.answer)) ||
          searchRegex.test(item.title) ||
          searchRegex.test(item.food_name)
      )
    }

    // Check if selected ranks and categories are provided
    if (selectedRank.length > 0) {
      filteredResults = filteredResults.filter((item) => selectedRank.includes(item.rank))
    }

    if (selectedCategory.length > 0) {
      filteredResults = filteredResults.filter((item) =>
        selectedCategory.includes(item.filter_category)
      )
    }

    return filteredResults
  }

  const itemsPerPage = 12
  useEffect(() => {
    const filteredResults = filterResults(
      questionBank?.results,
      rank,
      category,
      debouncedSearchTerm
    )

    const totalItems = filteredResults?.length || 0
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    setPage(totalPages)
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems)
    const questionList = filteredResults?.slice(startIndex, endIndex)
    setFilteredResults(filteredResults)
    setQuestionList(questionList)
  }, [questionBank, rank, category, debouncedSearchTerm, currentPage])

  useEffect(() => {
    dispatch(appActions.initFilters())
    dispatch(appActions.setFilters({ rank: [] }))
    dispatch(appActions.setFilters({ rank: [] }))
    dispatch(getCategoryCountAction())
    dispatch(getLikeDislikeListAction())
  }, [])

  useEffect(() => {
    dispatch(getCategoryCountAction())
  }, [questionBank])

  useEffect(() => {
    setCurrentPage(1)
  }, [rank, category, debouncedSearchTerm])

  useEffect(() => {
    const newData = [
      {
        title: "All",
        category: "ALL",
        count: categoryCount?.total_count
      }
    ]

    const categoryOrder = ["Food", "Beverage", "Wine Region", "Special"]

    categoryOrder?.forEach((categoryName) => {
      const categoryData = categoryCount?.categories_data?.find(
        (category) => category?.name === categoryName
      )
      categoryData?.sub_category?.forEach((subCategory) => {
        newData?.push({
          title: subCategory?.sub_category,
          category: categoryName,
          count: subCategory?.count
        })
      })
    })
    setQuestionCategoryCount(newData)
  }, [categoryCount])

  return (
    <>
      {openTriviaDialog && (
        <AddToTrivia
          onClose={handleAddTriviaDialogClose}
          data={triviaData}
          isIncorrect={isIncorrectQuestionTrivia}
        />
      )}
      {openChoice && (
        <LikeDislikeDialog
          open={openChoice}
          onClose={handleDialogClose}
          choice={choice}
          setChoice={setChoice}
          isLike={isLike}
          setIsLike={setIsLike}
        />
      )}
      <Header title="Question Bank">
        {!isIncorrectQuestionTrivia && (
          <>
            <Box className={styles.buttonContainer} onClick={() => handleChoice("like")}>
              <img src={likeIcon} className={styles.choiceImage} />
              <Typography variant="body3Medium" className={`${styles.count}`}>
                {likeDislikeData?.liked_count < 10
                  ? `0${likeDislikeData?.liked_count}`
                  : likeDislikeData?.liked_count}
              </Typography>
              <Typography variant="body4Medium" className={styles.choiceText}>
                {likeDislikeData?.liked_count > 1 ? "Likes" : "Like"}
              </Typography>
            </Box>
            <Box className={styles.buttonContainer} onClick={() => handleChoice("dislike")}>
              <img src={dislikeIcon} className={styles.choiceImage} />
              <Typography variant="body3Medium" className={`${styles.count}`}>
                {likeDislikeData?.disliked_count < 10
                  ? `0${likeDislikeData?.disliked_count}`
                  : likeDislikeData?.disliked_count}
              </Typography>
              <Typography variant="body4Medium" className={styles.choiceText}>
                {likeDislikeData?.disliked_count > 1 ? "Dislikes" : "Dislike"}
              </Typography>
            </Box>
            <div className={styles.separator}></div>

            <AppButton className={styles.addButton} onClick={handleAddQuestion}>
              Add Questions
            </AppButton>
          </>
        )}
      </Header>
      <Row className="d-flex">
        <Col lg="2">
          <AppSelect menuItems={filters} value={filter} onChange={handleFilter} />
        </Col>
      </Row>
      <Header
        title={isIncorrectQuestionTrivia ? "Incorrect Question Bank" : "Question Bank"}
        count={filteredResults?.length}
        back={false}
      />
      <QuestionsBanks
        title={isIncorrectQuestionTrivia ? "Incorrect Question Bank" : "Question Bank"}
        questions={questionList}
        pages={page}
        isEditable={true}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isTiriva={true}
        handleAddToTrivia={handleAddToTrivia}
        isDelete={true}
        questionFilter={true}
        setFilterQuestion={setFilterQuestion}
        filterQuestion={filterQuestion}
        filterOptions={sortOption}
        disableFilter={isIncorrectQuestionTrivia}
        itemsPerPage={itemsPerPage}
        month={month}
        setMonth={setMonth}
        platform={filter}
        questionCount={filteredResults?.length}
        categoryCount={true}
        showRank={true}
        RankOpt={RankOpt}
        questionCategoryCount={questionCategoryCount}
        isCMSAutoSuggest={true}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </>
  )
}

export default QuestionBank

const sortOption = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "In use",
    value: "true"
  },
  {
    label: "Not In Use",
    value: "false"
  },
  {
    label: "Most Incorrect Answered",
    value: "INCORRECT"
  },
  {
    label: "Liked Question",
    value: "like"
  },
  {
    label: "Disliked Question",
    value: "dislike"
  },
  {
    label: "Manual Question",
    value: "is_added_manually"
  }
]

const RankOpt = [1, 2, 3]

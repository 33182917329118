import React, { Fragment, useEffect, useState } from "react"
import { Box, Divider, Grid, InputAdornment, Typography } from "@mui/material"
import styles from "../../learning_development/trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import { useTheme } from "@mui/system"
import BankQuestions from "../../learning_development/QuestionBank/components/BankQuestions"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Card from "components/Card/Card"
import AppPagination from "components/StyledComponents/AppPagination"
import ImageDisplay from "components/ImageDisplayer"
import { Col } from "react-bootstrap"
import AppButton from "components/StyledComponents/AppButton"
import { useDispatch, useSelector } from "react-redux"
import Header from "components/Header/Header"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { addQuestionToQuizAction } from "redux/DMV/actions/user"
import { useHistory } from "react-router-dom"
import SearchIcon from "@mui/icons-material/Search"
import SearchTextField from "components/StyledComponents/SearchTextField"
import { useDebounce } from "hooks/utils"

function QuizQuestionBank() {
  const theme = useTheme()
  const style = {
    card: {
      padding: "24px 0"
    },
    box: {
      padding: "0px 24px",
      background: "none"
    },
    divider: {
      background: "#7f7f7f",
      height: "2px",
      opacity: 0.12
    },
    btnQuestion: {
      paddingLeft: "47px",
      paddingRight: "47px"
    },
    checkBoxPosition: {
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 14
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const [pages, setpages] = useState(1)
  const [questionList, setQuestionList] = useState([])
  const { quizQuestionBank } = useSelector((state) => state?.dmv)
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)

  const handleSelectQuestion = (ID) => {
    //check that question is already selected or not
    const isAvailable = selectedQuestions.filter((item) => item === ID)
    //if item is already selected remove that item from list
    if (isAvailable?.length > 0) {
      const updatedList = selectedQuestions.filter((item) => item !== ID)
      setSelectedQuestions(updatedList)
    } else {
      //add new item in list
      setSelectedQuestions([...selectedQuestions, ID])
    }
  }

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const checkSelected = (ID) => {
    let isItemSelected = selectedQuestions.filter((item) => item === ID)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleAddToQuiz = () => {
    setIsOpen(true)
  }

  const handleAdd = () => {
    const additionalQuestions = quizQuestionBank?.results
      .filter((question) => question.used_in_quiz === true)
      .map((question) => question.id)

    const payload = {
      questions: [...selectedQuestions, ...additionalQuestions]
    }
    dispatch(addQuestionToQuizAction({ payload, handleRoute }))
  }

  const handleRoute = () => {
    history.push("/dmv/dashboard/quiz")
  }

  const checkButtonDisable = () => {
    if (selectedQuestions?.length > 0) {
      return true
    } else {
      return false
    }
  }
  function filterResults(result, searchTerm) {
    let filteredResults = result
    if (searchTerm.trim() !== "") {
      const searchRegex = new RegExp(searchTerm.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
      filteredResults = filteredResults.filter(
        (item) =>
          item.answer.some((answer) => searchRegex.test(answer.answer)) ||
          searchRegex.test(item.title) ||
          searchRegex.test(item.menu_item_name)
      )
    }
    return filteredResults
  }

  useEffect(() => {
    const filteredResults = filterResults(quizQuestionBank?.results, debouncedSearchTerm)
    const itemsPerPage = 12
    const totalPages = Math.ceil(filteredResults?.length / itemsPerPage)
    const paginatedQuestionsList = filteredResults?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    )
    setpages(totalPages)
    setQuestionList(paginatedQuestionsList)
  }, [currentPage, debouncedSearchTerm, quizQuestionBank])

  useEffect(() => {
    setCurrentPage(1)
  }, [debouncedSearchTerm])

  return (
    <Fragment>
      <DeleteConfirmationDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={() => handleAdd()}
        title="Add Question"
        description={`Are you sure you want to add ${selectedQuestions?.length} questions?`}
        btnText="Add Question"
      />
      <Header title={"Question Bank"}></Header>
      <Grid container justifyContent={"flex-end"}>
        <Grid item xs={4}>
          <SearchTextField
            id="search"
            name="search"
            value={searchValue}
            onChange={handleSearchField}
            fullWidth
            type="search"
            placeholder="Search"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    backgroundColor: theme.palette.background.secondary
                  }}>
                  <SearchIcon htmlColor="#C4C4C4" />
                </InputAdornment>
              )
            }}
            style={{
              border: "1px solid #C4C4C4",
              backgroundColor: theme.palette.background.secondary,
              borderRadius: 6
            }}
          />
        </Grid>
      </Grid>
      <Card sx={style.card}>
        {questionList?.length > 0 ? (
          questionList?.map((question, index) => (
            <Fragment key={question?.id}>
              <Box
                key={question?.id}
                className={`${styles.questionCtr} ${
                  question?.is_used_in_trivia ? styles.question_added : ""
                }`}
                sx={style.box}>
                <Grid container>
                  <Grid item xs={10}>
                    <div className="d-flex">
                      <div
                        className={styles.countBorder}
                        style={{
                          borderColor: "#FFFFFF",
                          marginTop: 25,
                          marginRight: 10
                        }}>
                        {currentPage * 12 - (12 - 1) + index}
                      </div>
                      <div className="d-flex ml-2">
                        <div
                          className="me-3 mt-3 p-2 d-flex justify-content-center"
                          style={{ width: "233px", height: "120px" }}>
                          <ImageDisplay data={question.image} keyProp={"image"} />
                        </div>
                      </div>
                      <BankQuestions
                        question={question}
                        status={question?.status}
                        index={index}
                        isDMV={true}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                    }}>
                    <div>
                      <Grid container justifyContent="flex-end">
                        <div style={{ marginTop: 20 }}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              {checkSelected(question?.id) || question?.used_in_quiz ? (
                                <CheckCircleIcon
                                  htmlColor={theme.palette.secondary.main}
                                  onClick={() =>
                                    !question?.used_in_quiz && handleSelectQuestion(question?.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSelectQuestion(question?.id)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              {quizQuestionBank?.length - 1 > index && <Divider sx={style.divider} />}
            </Fragment>
          ))
        ) : (
          <Typography variant="h2" className="d-flex justify-content-center">
            No Questions Found
          </Typography>
        )}
        {quizQuestionBank?.pages > 0 && (
          <AppPagination
            onPageChange={setCurrentPage}
            totalPages={pages}
            currentPage={currentPage}
          />
        )}
      </Card>
      {quizQuestionBank?.results?.length > 0 && (
        <>
          <Col lg="12" className="d-flex justify-content-end">
            <AppButton
              styles={{ paddingLeft: 47, paddingRight: 47 }}
              disabled={checkButtonDisable() ? false : true}
              onClick={handleAddToQuiz}>
              Add To Quiz
            </AppButton>
          </Col>
        </>
      )}
    </Fragment>
  )
}

export default QuizQuestionBank

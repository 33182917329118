import React, { useState } from "react"
import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import styles from "../question.module.scss"
import Question from "./Question"
import ImageDisplay from "components/ImageDisplayer"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { useDispatch } from "react-redux"
import { deleteQuestionFromQuizAction } from "redux/DMV/actions/user"

const QuestionRow = ({ row, index, contestData, isEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const dispatch = useDispatch()

  const checkStatus = () => {
    if (contestData?.status?.toLowerCase() !== "published") {
      return true
    }
    return false
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  const handleDeleteQuestion = () => {
    dispatch(deleteQuestionFromQuizAction({ id: row?.id }))
    toggleDeletePopup(false)
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Question"
        description="Are you sure you want to delete this question?"
        onDelete={handleDeleteQuestion}
        onClose={toggleDeletePopup}
      />
      <TableRow key={row.id}>
        <TableCell>
          <div className={styles.serialNo}>{index + 1}</div>
        </TableCell>
        <TableCell style={{ cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)}>
          <div className="d-flex align-items-center">
            <Typography variant="body1Regular" className="me-2">
              {row?.title ?? "-"}
            </Typography>
            <span>
              {isExpanded ? (
                <KeyboardArrowUpIcon sx={{ width: "17.6px" }} />
              ) : (
                <KeyboardArrowDownIcon
                  className={`${!row?.suggested_by == "-" ? "d-none" : "d-inline-block"}`}
                  sx={{ width: "17.6px" }}
                />
              )}
            </span>
          </div>
        </TableCell>
        {checkStatus() ? (
          <TableCell align="center">
            <span role="button" onClick={toggleDeletePopup}>
              <DeleteOutlinedIcon
                color="red"
                sx={{
                  height: 30,
                  width: 30
                }}
              />
            </span>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell className={styles.expandableRow} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box className={styles.questionCtr}>
              <Question row={row} index={index} isEdit={isEdit} />
              <div className="d-flex">
                <div
                  className="p-2 d-flex align-items-center justify-content-center"
                  style={{ width: "200px", height: "auto" }}>
                  {row?.image ? (
                    <img className="img-fluid" style={{ borderRadius: "6px" }} src={row?.image} />
                  ) : (
                    <div
                      className="p-4"
                      style={{ border: "1px solid", borderRadius: "8px", width: "250px" }}>
                      <ImageDisplay />
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default QuestionRow

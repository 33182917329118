import React, { useEffect, useState } from "react"
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Grid,
  styled,
  OutlinedInput,
  Box,
  Typography,
  Checkbox
} from "@mui/material"
import { makeStyles } from "@material-ui/core"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { toTitleCase } from "helpers/functions"
import styles from "./filter.module.scss"
import { useTheme } from "@emotion/react"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
    maxWidth: "100%"
  }
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body2Regular,
  height: 38,
  width: "100%",
  borderRadius: 6,
  padding: "0px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  "& .MuiSelect-root:before": {
    borderBottom: "2px solid theme.palette.default.secondary !important"
  },
  "& .MuiSelect-select": {
    paddingLeft: "16px"
  },
  "&:focus": {
    borderRadius: 6
  },
  "& .MuiSvgIcon-root": {
    marginRight: "20px",
    color: theme.palette.text.primary
  },
  "&.Mui-error": {
    borderColor: "#A9402F !important"
  },
  "& .MuiSelect-select.Mui-disabled": {
    color: "#969698 !important",
    WebkitTextFillColor: "#969698"
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default} !important`,
  width: "100%",
  ...theme.typography.body2Regular,
  "&:hover": {
    backgroundColor: `${theme.palette.background.default} !important`
  }
}))

const FilterCheckBoxSelect = ({
  label,
  style = {},
  menuItems = [],
  value = [],
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState(value)
  const theme = useTheme()

  useEffect(() => {
    const allOptions = [...menuItems.map((item) => item.value)]
    if (value.includes("ALL")) {
      setSelectedOptions(allOptions)
    } else {
      setSelectedOptions(value)
    }
  }, [value, menuItems])

  const handleOptionChange = (event) => {
    const newValue = event.target.value
    const allOptions = [...menuItems.map((item) => item.value)]

    if (!newValue.includes("ALL") && selectedOptions.includes("ALL")) {
      setSelectedOptions([])
      onChange([])
    } else if (newValue.includes("ALL") && !selectedOptions.includes("ALL")) {
      setSelectedOptions(allOptions)
      onChange(allOptions)
    } else if (selectedOptions.length === allOptions.length) {
      const deselectedOption = allOptions.find((option) => !newValue.includes(option))
      if (deselectedOption) {
        const filteredValue = newValue.filter((value) => value !== "ALL")
        setSelectedOptions(filteredValue)
        onChange(filteredValue)
      }
    } else if (newValue.length === menuItems.length - 1) {
      setSelectedOptions(allOptions)
      onChange(allOptions)
    } else {
      setSelectedOptions(newValue)
      onChange(newValue)
    }
  }

  const renderSelectedValue = () => {
    if (selectedOptions.length === 0) {
      return (
        <Box className={styles.filterContainer}>
          <Typography variant="body2Regular">{label}</Typography>
          <Box>
            <Typography variant="caption" sx={{ color: "#274C9A", fontWeight: "600" }}>
              All
            </Typography>
          </Box>
        </Box>
      )
    }

    if (selectedOptions.includes("ALL")) {
      return (
        <Box className={styles.filterContainer}>
          <Typography variant="body2Regular">{label}</Typography>
          <Box>
            <Typography variant="caption" sx={{ color: "#274C9A", fontWeight: "600" }}>
              All
            </Typography>
          </Box>
        </Box>
      )
    }

    const firstOptionLabel = selectedOptions[0]
    const formattedFirstOptionLabel =
      typeof firstOptionLabel === "string"
        ? toTitleCase(firstOptionLabel.replace(/_/g, " "))
        : firstOptionLabel

    return (
      <Box className={styles.filterContainer}>
        <Typography variant="body2Regular">{label}</Typography>
        <Box>
          <Typography variant="caption" sx={{ color: "#274C9A", fontWeight: "600" }}>
            {formattedFirstOptionLabel}
            {selectedOptions.length > 1 ? ` +${selectedOptions.length - 1}` : ""}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.container}>
      <Grid container flexDirection="column" style={style}>
        <FormControl className={classes.formControl}>
          <StyledSelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            IconComponent={KeyboardArrowDownIcon}
            variant="standard"
            multiple
            value={selectedOptions}
            onChange={handleOptionChange}
            renderValue={renderSelectedValue}
            displayEmpty
            input={<OutlinedInput />}
            inputProps={{ "aria-label": "Without label" }}
            {...props}>
            {menuItems.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
                <Checkbox
                  checked={selectedOptions.includes(option.value)}
                  className={styles.checkbox}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 24, color: theme.palette.secondary.main }
                  }}
                />
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default FilterCheckBoxSelect

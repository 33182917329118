import { Grid, List, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQuizDataAction } from "redux/DMV/actions/user"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { getFormattedDate } from "helpers/functions"
import EditQuizQuestion from "../addQuiz/EditPendingQuestion"

const ViewQuiz = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const { quizId, quizData } = useSelector((state) => state.dmv)

  const duration = `${getFormattedDate(quizData?.start_date)} - ${getFormattedDate(
    quizData?.end_date
  )}`

  const rest_names = quizData?.restaurant_data?.map((restaurant) => restaurant.name).join(", ")

  const styles = {
    list: {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      marginBottom: "18px"
    },
    label: {
      color: theme.palette.text.secondary,
      opacity: 0.6,
      marginBottom: "4px"
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getQuizDataAction(quizId))
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header title="View Quiz" breadCrumbs={breadCrumbs}>
          {quizData?.status?.toLowerCase() === "draft" && (
            <AppButton
              styles={{ padding: "13px 80px" }}
              onClick={() => {
                history.push("/dmv/dashboard/quiz/edit-quiz")
              }}>
              Edit Quiz
            </AppButton>
          )}
        </Header>
      </Grid>
      <Grid item xs={12}>
        <Row>
          <Col lg="4">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Quiz Name
              </Typography>
              <Typography variant="body1Regular">{quizData?.name ?? "-"}</Typography>
            </List>
          </Col>
          <Col lg="4">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Restaurant
              </Typography>
              <Typography variant="body1Regular">
                {quizData?.all_restaurants ? "All" : rest_names}
              </Typography>
            </List>
          </Col>

          <Col lg="4">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Category
              </Typography>
              <Typography variant="body1Regular">{quizData?.category}</Typography>
            </List>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Duration
              </Typography>
              <Typography variant="body1Regular">{duration}</Typography>
            </List>
          </Col>
          <Col lg="4">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Time limit per question
              </Typography>
              <Typography variant="body1Regular">{quizData?.duration} Seconds</Typography>
            </List>
          </Col>
        </Row>

        <Container fluid className="mt-5">
          <Row>
            <Col lg="12">
              <Typography variant="h2">Questions</Typography>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <EditQuizQuestion
                rows={quizData?.questions ?? []}
                columns={PendingColumns}
                contestData={quizData}
              />
            </Col>
          </Row>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ViewQuiz

const PendingColumns = [
  {
    id: "#",
    label: "#",
    minWidth: 35
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 300
  }
]

import { Grid, Popover, Radio, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import downloadIcon from "assets/icons/Download.svg"
import trendUpIcon from "assets/icons/trending-up.svg"
import ExportData from "./exportData"
import WeeklyAnalyticsTable from "./analyticsTable/components/weeklyTable"
import { useDispatch, useSelector } from "react-redux"
import { getWeeklyAnalyticsAction } from "redux/DMV/actions/user"
import { appActions } from "redux/app/slice/app"
import { useHistory } from "react-router-dom"

const AnalyticsDashboard = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedFilter, setSelectedFilter] = useState("weekly")

  const dispatch = useDispatch()
  const history = useHistory()
  const { weeklyAnalytics } = useSelector((state) => state.dmv)
  const { restaurant, period } = useSelector((store) => store?.app?.filters || {})

  const theme = useTheme()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const handleSelectedFilter = (value) => {
    if (value === "monthly") history.push("analytics-dashboard/monthly-analytics")
    setSelectedFilter(value)
    handleClose()
  }

  useEffect(() => {
    dispatch(getWeeklyAnalyticsAction({ restaurant, period }))
  }, [selectedFilter, restaurant, period])

  useEffect(() => {
    dispatch(appActions.initFilters())
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Analytics Dashboard"
  }, [])

  return (
    <Grid container>
      {openModal && <ExportData onClose={() => setOpenModal()} analyticsType={"trend-analytics"} />}
      <Grid item xs={12}>
        <Header title="Analytics Dashboard" breadCrumbs={breadCrumbs}>
          <AppButton
            aria-describedby={id}
            styles={{ padding: "13px 20px", marginRight: "20px" }}
            onClick={handleClick}>
            <img src={trendUpIcon} style={{ marginRight: "8px" }} />
            Trend Analysis
          </AppButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              "& .MuiPopover-paper": {
                width: "196px",
                backgroundColor: theme.palette.background.main,
                border: "1px solid #fff",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "7px",
                borderBottomRightRadius: "7px"
              }
            }}
            marginThreshold={110}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}>
            <Grid item px={2} py={1}>
              {Filters?.map((item, index) => (
                <Grid
                  container
                  key={index}
                  py={1}
                  justifyContent={"space-between"}
                  onClick={() => handleSelectedFilter(item.value)}>
                  <Typography>{item.label}</Typography>
                  <Radio role="button" color="primary" checked={item.value === selectedFilter} />
                </Grid>
              ))}
            </Grid>
          </Popover>
          <AppButton styles={{ padding: "13px 30px" }} onClick={() => setOpenModal(true)}>
            <img src={downloadIcon} style={{ marginRight: "8px" }} />
            Export
          </AppButton>
        </Header>
      </Grid>
      <Grid item xs={12}>
        <WeeklyAnalyticsTable rows={weeklyAnalytics} />
      </Grid>
    </Grid>
  )
}

export default AnalyticsDashboard

const Filters = [
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  }
]

import { List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import React from "react"
import { useHistory } from "react-router-dom"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

const QuizCard = () => {
  const history = useHistory()

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Quiz</Typography>
          <span onClick={() => history.push("/dmv/dashboard/quiz")}>
            <IconButtonWithBorder islight={true}>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
      </List>
    </Card>
  )
}

export default QuizCard

import React, { useEffect, useState } from "react"
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  styled
} from "@mui/material"
import styles from "../../analytics.module.scss"
import TableNoRecords from "components/TableNoRecords"
import { useSelector } from "react-redux"
import { formatNumberToThousand } from "helpers/functions"
import AppPagination from "components/StyledComponents/AppPagination"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 27
    },
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const WeeklyAnalyticsTable = ({ rows }) => {
  const [filteredList, setFilteredList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const { metrics } = useSelector((state) => state?.app?.filters || {})

  const filterRows = (metrics, rows) => {
    if (metrics.includes("ALL")) {
      setFilteredList(rows)
    } else {
      const filteredRows = rows?.map((row) => {
        const filteredData = row.data.filter((item) => metrics.includes(item.name))
        return { ...row, data: filteredData }
      })
      setFilteredList(filteredRows)
    }
  }

  const itemsPerPage = 12
  const totalPages = Math.ceil(filteredList?.length / itemsPerPage)
  const paginatedList =
    Array.isArray(filteredList) &&
    filteredList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  useEffect(() => {
    filterRows(metrics, rows)
  }, [rows, metrics])

  useEffect(() => {
    setCurrentPage(1)
  }, [rows])

  return (
    <>
      {!paginatedList?.length ? (
        <Box className={styles.noDataContainer}>
          <Typography variant="h2">No Data Found</Typography>
        </Box>
      ) : (
        paginatedList?.map((restaurant, restaurantIndex) => (
          <Box key={restaurantIndex} style={{ marginTop: "2rem" }}>
            <Typography variant="h6">{restaurant.name}</Typography>
            <TableContainer sx={{ maxHeight: 800 }}>
              <StyledTable stickyHeader aria-label={`table-${restaurant.name}`}>
                {!paginatedList?.[0]?.data?.length ? (
                  <TableNoRecords colSpan={9} label="No Data Found" />
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.totalCell}>
                          <Typography variant="body4Regular" className={styles.headerText}>
                            Analytics
                          </Typography>
                        </TableCell>
                        {Object.keys(restaurant.data[0] || {})
                          .filter((key) => key !== "name")
                          .map((weekKey, index) => (
                            <TableCell
                              key={index}
                              align="center"
                              className={[styles.quizCell, { [styles.evenCell]: index % 2 === 0 }]}>
                              <Typography variant="body1Regular" className={styles.headerText}>
                                {weekKey}
                              </Typography>
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {restaurant?.data?.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell
                            align="left"
                            className={[
                              styles.totalCell,
                              row.name === "Games Played" && styles.gamesPlayed
                            ].join(" ")}>
                            <Typography
                              variant={
                                row.name === "Total Games" ? "body3Regular" : "body1Regular"
                              }>
                              {row.name}
                            </Typography>
                          </TableCell>
                          {Object.keys(row)
                            ?.filter((key) => key !== "name")
                            ?.map((weekKey, cellIndex) => (
                              <TableCell
                                key={cellIndex}
                                align="center"
                                className={[
                                  cellIndex % 2 === 0 && styles.evenCell,
                                  row.name === "Quiz Games Played" && styles.quizGamesPlayed,
                                  row.name === "Games Played" && styles.gamesPlayed
                                ]
                                  .filter(Boolean)
                                  .join(" ")}>
                                <Typography
                                  variant={
                                    row.name === "Total Games" ? "body3Regular" : "body1Regular"
                                  }>
                                  {formatNumberToThousand(row[weekKey])}
                                </Typography>
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                )}
              </StyledTable>
            </TableContainer>
          </Box>
        ))
      )}
      {rows?.length ? (
        <AppPagination
          onPageChange={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default WeeklyAnalyticsTable

import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import FrontLineUserForm from "./frontLineUserForm"
import { validationSchemaFrontLineUser } from "helpers/validationSchema"
import { formatDate } from "helpers/functions"
import { useDispatch } from "react-redux"
import { uploadFrontlineUserAction } from "redux/superAdmin/actions"
import { useHistory } from "react-router-dom"

const initialValues = {
  role: "",
  fullName: "",
  email: "",
  contactNumber: "",
  address: "",
  hireDate: null,
  cms_access: false,
  analytics_access: false,
  raffle_access: true
}

const AddFrontLineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue] = useState(initialValues)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
  }, [])

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      country_code: "+1",
      mobile: values?.contactNumber,
      emp_job_class: values?.role,
      address: values?.address,
      hire_date: values?.hireDate ? formatDate(new Date(values?.hireDate)) : null,
      cms_access: values?.cms_access === "true" || values?.cms_access === true,
      analytics_access: values?.analytics_access === "true" || values?.analytics_access === true,
      raffle_access: values?.raffle_access === "true" || values?.raffle_access === true
    }
    const handleRoute = () => {
      history.replace("/super-admin/dashboard/frontline-user-list")
    }
    dispatch(uploadFrontlineUserAction({ payload, onClose: handleRoute, user_type: "" }))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Add Frontline User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaFrontLineUser}
        initialValues={initialFormValue}>
        {(formik) => <FrontLineUserForm formik={formik}></FrontLineUserForm>}
      </Formik>
    </>
  )
}

export default AddFrontLineUser

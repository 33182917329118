// import AppButton from "components/StyledComponents/AppButton"
import Header from "components/Header/Header"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDMVAutoSuggestQuestionsAction } from "redux/DMV/actions/user"
import AddQuestion from "./AddQuestionModal"
import { getNewRestaurantListAction } from "redux/superAdmin/actions"
import AddQuestionToQuiz from "./AddQuestionToQuizModal"

const DMV_AutoSuggest = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const { DMVAutoSuggestQuestions } = useSelector((state) => state.dmv)
  const [openRestaurant, setOpenRestaurant] = useState(false)
  const [questionData, setQuestionData] = useState({})
  const [openQuizDialog, setOpenQuizDialog] = useState(false)

  const handleQuestionBankData = (data) => {
    setOpenRestaurant(true)
    setQuestionData(data)
  }

  const handleAddToQuiz = (data) => {
    setOpenQuizDialog(true)
    setQuestionData(data)
  }

  useEffect(() => {
    dispatch(getDMVAutoSuggestQuestionsAction({ page: currentPage }))
  }, [currentPage])

  useEffect(() => {
    dispatch(getNewRestaurantListAction())
  }, [])

  return (
    <>
      {openRestaurant && (
        <AddQuestion onClose={() => setOpenRestaurant()} data={questionData} isAutoSuggest={true} />
      )}

      {openQuizDialog && (
        <AddQuestionToQuiz
          onClose={() => setOpenQuizDialog(false)}
          data={questionData}
          isAutoSuggest={true}
        />
      )}

      <Header title="Auto Suggestion Questionnaire" />
      <QuestionsBanks
        title=""
        questions={DMVAutoSuggestQuestions?.results}
        pages={DMVAutoSuggestQuestions?.pages}
        isEditable={true}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isTiriva={false}
        isGamification={true}
        isAutoSuggest={true}
        handleAddToTrivia={handleQuestionBankData}
        isDMV={true}
        handleAddToQuiz={handleAddToQuiz}
      />
    </>
  )
}

export default DMV_AutoSuggest

import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Typography } from "@mui/material"
import styles from "./question.module.scss"
import { styled } from "@mui/system"
import QuestionRow from "./components/QuestionRow"
import TableNoRecords from "components/TableNoRecords"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const EditQuizQuestion = ({ rows = [], columns = [], contestData, isEdit }) => {
  return (
    <TableContainer sx={{ maxHeight: 600 }}>
      <StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <>
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography className={styles.header} variant="body2Regular">
                    {column.label}
                  </Typography>
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            rows?.map((row, index) => {
              return (
                <QuestionRow
                  key={row.id}
                  row={row}
                  index={index}
                  contestData={contestData}
                  isEdit={isEdit}
                />
              )
            })
          ) : (
            <TableNoRecords colSpan={9} label="No Data Found" />
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default EditQuizQuestion

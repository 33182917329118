import { useTheme } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { validationSchemaQuiz } from "helpers/validationSchema"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AddQuizForm from "../addQuiz/addQuizForm"
import { editQuizAction, getQuizDataAction } from "redux/DMV/actions/user"
import { formatDate } from "helpers/functions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
const EditQuiz = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const statusRef = useRef("AddQuestions")
  const { quizData, quizId } = useSelector((state) => state.dmv)

  const initialFormValue = {
    quizName: quizData?.name,
    restaurants: quizData?.all_restaurants ? ["All"] : quizData?.restaurants,
    category: quizData?.category,
    start_date: quizData?.start_date,
    end_date: quizData?.end_date,
    timeLimit: quizData?.duration
  }

  const handleSubmit = (values) => {
    let RestaurantList = values.restaurants
    const valueLength = RestaurantList.length
    let updatedOptions = []

    if (RestaurantList?.[valueLength - 1] === "All") {
      updatedOptions = RestaurantList.filter((value) => value === "All")
    } else if (RestaurantList?.[0] === "All" && valueLength > 1) {
      updatedOptions = RestaurantList.filter((value) => value !== "All")
    } else {
      updatedOptions = RestaurantList
    }

    const payload = {
      name: values?.quizName,
      start_date: values?.start_date ? formatDate(values.start_date) : formatDate(new Date()),
      end_date: formatDate(values?.end_date),
      status: statusRef.current === "AddQuestions" ? "DRAFT" : statusRef.current,
      category: values?.category,
      duration: values.timeLimit,
      restaurants: updatedOptions.length && updatedOptions[0] !== "All" ? updatedOptions : [],
      all_restaurants: values.restaurants?.length > 1 ? false : true
    }
    dispatch(
      editQuizAction({
        payload,
        type: statusRef.current === "AddQuestions" ? "questionBank" : "edit",
        handleRoute
      })
    )
  }

  const handleRoute = () => {
    statusRef.current === "AddQuestions"
      ? history.replace("/dmv/dashboard/quiz/question-bank")
      : history.replace("/dmv/dashboard/quiz")
  }

  const handleSaveAsDraft = ({ handleSubmit }) => {
    statusRef.current = "DRAFT"
    handleSubmit()
  }

  const handlePublish = ({ handleSubmit }) => {
    statusRef.current = "PUBLISHED"
    handleSubmit()
  }

  const checkStatus = () => {
    if (quizData?.status?.toLowerCase() === "published") {
      return new Date(quizData?.start_date) > new Date()
    }
    return true
  }

  const handleAddQuestion = (formik) => {
    statusRef.current = "AddQuestions"
    formik.handleSubmit()
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getQuizDataAction(quizId))
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaQuiz}
          initialValues={initialFormValue}>
          {(formik) => (
            <>
              <Grid item xs={12}>
                <Header title="DMV Quiz" breadCrumbs={breadCrumbs}>
                  {checkStatus() ? (
                    <Typography
                      variant="body1Regular"
                      onClick={() => handleSaveAsDraft(formik)}
                      style={{
                        color: theme.palette.white.main,
                        textDecoration: "underline",
                        margin: "13px 50px",
                        cursor: "pointer",
                        textUnderlineOffset: "2px"
                      }}>
                      Save Draft
                    </Typography>
                  ) : null}
                  <AppButton
                    sx={{ padding: "13px 80px" }}
                    onClick={() => {
                      handlePublish(formik)
                    }}
                    disabled={!quizData?.questions?.length}>
                    Publish
                  </AppButton>
                </Header>
              </Grid>
              <AddQuizForm
                formik={formik}
                questions={quizData?.questions}
                isEdit={true}
                handleAddQuestion={handleAddQuestion}
              />
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default EditQuiz

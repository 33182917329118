import { createSlice } from "@reduxjs/toolkit"

const user = createSlice({
  name: "user",
  initialState: {
    userData: {},
    userWithId: {},
    restaurantList: [],
    userRestaurant: {},
    isLightEnabled: false
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setUserWithData: (state, action) => {
      state.userWithId = action.payload
    },
    setUserRestaurantList: (state, action) => {
      state.restaurantList = action.payload
    },
    setUserRestaurant: (state, action) => {
      state.userRestaurant = action.payload
    },
    setIsLightEnabled: (state, action) => {
      state.isLightEnabled = action.payload
    }
  }
})

export default user
export const userActions = user.actions

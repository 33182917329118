import React, { Fragment, useState, useEffect } from "react"
import Card from "components/Card/Card"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { Box, Menu, MenuItem, styled, TextField, Tooltip, Typography } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import CalendarIcon2 from "assets/icons/CalendarIcon2.svg"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import "./FilterItem.scss"
import { useTheme } from "@mui/system"
import { formatDate } from "helpers/functions"
import { useDispatch, useSelector } from "react-redux"
import { appActions } from "redux/app/slice/app"
import moment from "moment"
import { Link } from "react-router-dom"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import CheckBoxSelect from "components/StyledComponents/CheckboxSelect"
import { generateWeekRangesForMonth, trimText } from "helpers/functions"
import { DMVActions } from "redux/DMV/slice/user"
import FilterCheckBoxSelect from "components/FilterCheckBox"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "8px 18px",
  borderTop: `2px solid ${theme.palette.background.default}`,
  "&:hover": {
    backgroundColor: theme.palette.drawer.main
  },
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.drawer.main} !important`
  }
}))

const FilterItem = ({ data }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(false)
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const [activeView, setActiveView] = React.useState("month")
  const [calendarFilterValue, setCalendarFilterValue] = React.useState(new Date())
  const [selectedValue, setSelectedValue] = React.useState(data.default)
  const [weekOptions, setWeekOptions] = useState([])

  const [dayFilterValue, setDayFilterValue] = React.useState(new Date())
  const [activeViewDay, setActiveViewDay] = React.useState("month")

  const [isCollapsedDay, setIsCollapsedDay] = React.useState(true)
  const { date, weeks } = useSelector((store) => store?.app?.filters || {})
  const { userRestaurant } = useSelector((store) => store.user)
  const [restaurantList, setRestaurantList] = useState([])
  const [periodList, setPeriodList] = useState([])
  const { userRole } = useSelector((store) => store?.superAdmin ?? [])
  const { restaurantList: restaurants } = useSelector((store) => store.user)

  const inlineStyles = {
    toolbarCard: {
      margin: "6px",
      paddingTop: "8px",
      paddingBottom: "8px",
      borderRadius: "12px",
      backgroundColor: theme.palette.primary.main
    },
    yearLabel: {
      color: theme.palette.secondary.main,
      cursor: "pointer"
    },
    bottomButtons: {
      color: theme.palette.secondary.main,
      cursor: "pointer"
    }
  }

  // Calendar top toolbar
  function Toolbar() {
    return (
      <Card style={inlineStyles.toolbarCard}>
        <Typography
          onClick={() => setActiveView("year")}
          variant="body1Regular"
          sx={inlineStyles.yearLabel}>
          {calendarFilterValue.getFullYear()}
        </Typography>
      </Card>
    )
  }

  // Calendar top toolbar
  function ToolbarDay() {
    return (
      <Card style={inlineStyles.toolbarCard}>
        <Typography
          onClick={() => setActiveViewDay("year")}
          variant="body1Regular"
          sx={inlineStyles.yearLabel}>
          {dayFilterValue.getFullYear() || "-"}
        </Typography>
      </Card>
    )
  }

  const handleFilter = (e) => {
    if (data.isCalender || data.isDateFilter) {
      setActiveViewDay("month")
      setActiveView("month")
      setCalendarFilterValue(new Date(date))
    } else {
      setAnchorEl(e.currentTarget)
    }
    setIsCollapsed((prev) => !prev)
    setIsCollapsedDay((prev) => !prev)
  }

  const handleFilterDay = (e) => {
    if (data.isDateFilter) {
      setActiveViewDay("month")
      setDayFilterValue(new Date(date))
    } else {
      setAnchorEl(e.currentTarget)
    }
    setIsCollapsedDay((prev) => !prev)
  }

  const handleDropdownChange = (menuItem, value) => {
    setSelectedValue(menuItem)
    if (value === "restaurant") dispatch(DMVActions.setSelectedRestaurant(menuItem.label))
    let payload = {}
    if (data?.isCheckBox || data?.isWeek) {
      payload[data.key] = menuItem
    } else {
      if (value === "metrics") {
        payload[data.key] = menuItem
      } else {
        payload[data.key] = menuItem.value
      }
    }
    dispatch(appActions.setFilters(payload))
    setIsCollapsed(true)
  }

  const handleClose = () => {
    setActiveView("month")
    setIsCollapsed(true)
  }

  const handleCloseDay = () => {
    setActiveViewDay("month")
    setIsCollapsed(true)
    setIsCollapsedDay(true)
  }

  const okayHandling = () => {
    if (activeView === "month") {
      setActiveView("year")
    } else {
      dispatch(appActions.setFilters({ date: formatDate(calendarFilterValue) }))
      handleClose()
    }
  }

  const okayHandlingDay = () => {
    if (activeViewDay === "month") {
      setActiveViewDay("year")
    } else {
      dispatch(appActions.setFilters({ date: formatDate(dayFilterValue) }))
      handleCloseDay()
    }
  }

  const okayHandlingYear = () => {
    dispatch(appActions.setFilters({ date: formatDate(calendarFilterValue) }))
    handleClose()
  }

  function resetDate() {
    dispatch(appActions.setFilters({ date: "" }))
    handleCloseDay()
  }

  const getMonthYearFromDateCustom = (dateObj, isDate) => {
    if (isDate) {
      return moment(dateObj).format("DD MMM YYYY")
    }
    return moment(dateObj).format("MMM YYYY")
  }

  const restaurantFilterList = [
    {
      label: "All",
      value: "ALL"
    }
  ]
  React.useEffect(() => {
    if (data?.isRestaurant) {
      setRestaurantList([
        ...restaurantFilterList,
        ...restaurants.map((item) => ({
          label: `${item?.name}`,
          value: `${item?.id}`
        }))
      ])
    } else {
      setRestaurantList([
        ...restaurantFilterList,
        {
          label: `${userRestaurant?.name || "Scarpetta"}`,
          value: `${userRestaurant?.name || "Scarpetta"}`
        }
      ])
    }
    if (data?.type === "week") {
      setPeriodList(
        Array.from({ length: 52 }, (_, i) => ({
          label: `last ${i + 1} ${i + 1 > 1 ? "weeks" : "week"}`,
          value: (i + 1).toString()
        }))
      )
    } else {
      setPeriodList(
        Array.from({ length: 12 }, (_, i) => ({
          label: (i + 1).toString(),
          value: (i + 1).toString()
        }))
      )
    }
  }, [])

  useEffect(() => {
    setSelectedValue(data.default)
  }, [])

  useEffect(() => {
    if (date) {
      const formattedDate = new Date(date)
      const month = formattedDate.getMonth()
      const year = formattedDate.getFullYear()
      const week = generateWeekRangesForMonth(year, month)
      week.unshift({
        label: "All",
        range: null,
        value: {
          start: null,
          end: null
        }
      })
      setWeekOptions(week)
    }
  }, [date])

  return (
    <Fragment>
      {!data.isSearch && !data.isRedirect && !data.isCheckBox && !data?.isMetrics ? (
        !data?.isCalenderDisabled ? (
          <div
            className="filter d-flex justify-content-between align-items-center"
            role="button"
            onClick={data.isDateFilter ? handleFilterDay : handleFilter}>
            <div className="d-flex flex-column">
              <Typography variant="body2Regular">{data.title}</Typography>
              <Typography
                sx={(theme) => ({
                  color: window.location.pathname.includes("DMV")
                    ? theme.palette.secondary.default
                    : theme.palette.secondary.main,
                  opacity: 1
                })}
                variant="caption3">
                {data.isCalender || data.isDateFilter ? (
                  date ? (
                    data?.key === "year" ? (
                      new Date(date).getFullYear()
                    ) : (
                      getMonthYearFromDateCustom(date, data.isDateFilter)
                    )
                  ) : (
                    "-"
                  )
                ) : data.isWeek ? (
                  <>
                    <div>{weeks?.label}</div>
                    <div>{weeks?.range}</div>
                  </>
                ) : (
                  selectedValue?.label || data?.name
                )}
              </Typography>
              {data.name2 ? (
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                    opacity: 1
                  })}
                  variant="caption3">
                  {data.name2}
                </Typography>
              ) : null}
            </div>
            <span>
              {data.isCalender || data.isDateFilter ? (
                <img className="me-1" src={CalendarIcon2} />
              ) : (
                <>{isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}</>
              )}
            </span>
          </div>
        ) : (
          <div className="filter d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <Typography variant="body2Regular">{data.title}</Typography>
              <Typography
                sx={(theme) => ({
                  color: window.location.pathname.includes("DMV")
                    ? theme.palette.secondary.default
                    : theme.palette.secondary.main,
                  opacity: 1
                })}
                variant="caption3">
                {data.isCalender || data.isDateFilter
                  ? date
                    ? getMonthYearFromDateCustom(date, data.isDateFilter)
                    : "-"
                  : selectedValue?.label || data?.name}
              </Typography>
            </div>
          </div>
        )
      ) : null}
      {!isCollapsed && !data.isCalender ? (
        <Menu
          anchorEl={anchorEl}
          open={!isCollapsed}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            sx: {
              borderRadius: 0,
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
              backgroundColor: theme.palette.background.default,
              "& .MuiList-root": {
                backgroundColor: theme.palette.background.default,
                width: "209px",
                maxHeight: "300px",
                boxSizing: "border-box",
                padding: 0
              }
            }
          }}>
          {data?.key === "restaurant"
            ? restaurantList?.map((menuItem, index) => (
                <StyledMenuItem
                  key={`${data?.key}-${index}`}
                  onClick={() => handleDropdownChange(menuItem, "restaurant")}>
                  {menuItem?.label && menuItem.label.length > 20 ? (
                    <Tooltip title={menuItem.label}>
                      <Typography>{trimText(menuItem.label, 20)}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{menuItem?.label || ""}</Typography>
                  )}
                </StyledMenuItem>
              ))
            : data?.key === "employeeClass"
            ? userRole?.map((menuItem, index) => (
                <StyledMenuItem
                  key={`${data?.key}-${index}`}
                  onClick={() => handleDropdownChange(menuItem)}>
                  {menuItem?.label && menuItem.label.length > 22 ? (
                    <Tooltip title={menuItem.label}>
                      <Typography>{trimText(menuItem.label, 22)}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{menuItem?.label || ""}</Typography>
                  )}
                </StyledMenuItem>
              ))
            : data?.key === "period"
            ? periodList?.map((menuItem, index) => (
                <StyledMenuItem
                  key={`${data?.key}-${index}`}
                  onClick={() => handleDropdownChange(menuItem)}>
                  {menuItem?.label && menuItem.label.length > 20 ? (
                    <Tooltip title={menuItem.label}>
                      <Typography>{trimText(menuItem.label, 20)}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{menuItem?.label || ""}</Typography>
                  )}
                </StyledMenuItem>
              ))
            : data?.menu?.map((menuItem, index) => (
                <StyledMenuItem
                  key={`${data?.key}-${index}`}
                  onClick={() => handleDropdownChange(menuItem)}>
                  {menuItem.label}
                </StyledMenuItem>
              ))}
        </Menu>
      ) : null}
      {!isCollapsed && data.isCalender ? (
        <div className={"pickerContainer"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {activeView === "month" ? (
              // Month Picker
              <StaticDatePicker
                id={"MonthPicker"}
                key={"MonthPicker"}
                displayStaticWrapperAs="desktop"
                openTo={"month"}
                views={["month"]}
                value={calendarFilterValue}
                showToolbar={true}
                ToolbarComponent={Toolbar}
                onChange={(newValue) => {
                  const lastDate = new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0)
                  setCalendarFilterValue(lastDate)
                }}
                renderInput={(params) => <TextField {...params} />}
                // disableFuture={true}
              />
            ) : (
              // Year Picker
              <StaticDatePicker
                id={"YearPicker"}
                key={"YearPicker"}
                displayStaticWrapperAs="desktop"
                openTo={"year"}
                views={["year"]}
                value={calendarFilterValue}
                showToolbar={true}
                ToolbarComponent={Toolbar}
                minDate={new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)}
                maxDate={new Date().setUTCFullYear(new Date().getUTCFullYear() + 1)}
                autoFocus={true}
                onChange={(newValue) => {
                  setCalendarFilterValue(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
                // disableFuture={true}
              />
            )}
          </LocalizationProvider>

          {/* Bottom Buttons Container */}
          <Box className={"bottomButtonContainer"}>
            <Typography
              className={`me-4`}
              sx={inlineStyles.bottomButtons}
              onClick={handleClose}
              variant="caption5">
              Cancel
            </Typography>
            <Typography
              className={`me-3 pe-1`}
              sx={inlineStyles.bottomButtons}
              variant="caption5"
              onClick={okayHandling}>
              OK
            </Typography>
          </Box>
        </div>
      ) : null}
      {!isCollapsed && data.isCalender && data?.key === "year" ? (
        <div className={"pickerContainer"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              id={"YearPicker"}
              key={"YearPicker"}
              displayStaticWrapperAs="desktop"
              openTo={"year"}
              views={["year"]}
              value={calendarFilterValue}
              showToolbar={true}
              ToolbarComponent={Toolbar}
              minDate={new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)}
              maxDate={new Date().setUTCFullYear(new Date().getUTCFullYear() + 1)}
              autoFocus={true}
              onChange={(newValue) => {
                setCalendarFilterValue(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    value: calendarFilterValue ? `${calendarFilterValue.getFullYear()}` : ""
                  }}
                />
              )}
            />
          </LocalizationProvider>

          {/* Bottom Buttons Container */}
          <Box className={"bottomButtonContainer"}>
            <Typography
              className={`me-4`}
              sx={inlineStyles.bottomButtons}
              onClick={handleClose}
              variant="caption5">
              Cancel
            </Typography>
            <Typography
              className={`me-3 pe-1`}
              sx={inlineStyles.bottomButtons}
              variant="caption5"
              onClick={okayHandlingYear}>
              OK
            </Typography>
          </Box>
        </div>
      ) : null}
      {!isCollapsed && data.isWeek ? (
        <Menu
          anchorEl={anchorEl}
          open={!isCollapsed}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            sx: {
              borderRadius: 0,
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
              backgroundColor: theme.palette.background.default,
              "& .MuiList-root": {
                backgroundColor: theme.palette.background.default,
                width: "209px",
                maxHeight: "300px",
                boxSizing: "border-box",
                padding: 0
              }
            }
          }}>
          <div style={{ overflowX: "auto" }}>
            {weekOptions.map((week, index) => (
              <StyledMenuItem
                key={`${data?.key}-${index}`}
                onClick={() => handleDropdownChange(week)}>
                {week.label}
                {week.range}
              </StyledMenuItem>
            ))}
          </div>
        </Menu>
      ) : null}

      {data.isRedirect ? (
        <div className="d-flex ps-4 pt-3 pb-2 pe-3" style={{ borderBottom: `1px solid #212121` }}>
          <Link to={data.link} className="w-100">
            <div className="d-flex justify-content-between ">
              <div>
                <Typography
                  variant="body1Regular"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}>
                  {data.name}
                </Typography>
              </div>
              <div className="d-flex align-items-center">
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </div>
            </div>
          </Link>
        </div>
      ) : null}

      {!isCollapsedDay && data.isDateFilter ? (
        <div className={"pickerContainer datePicker"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {activeViewDay === "month" ? (
              // Month Picker
              <StaticDatePicker
                id={"dayPicker"}
                key={"dayPicker"}
                displayStaticWrapperAs="desktop"
                openTo={"day"}
                views={["day", "month"]}
                value={dayFilterValue}
                showToolbar={true}
                ToolbarComponent={ToolbarDay}
                onChange={(newValue) => {
                  const lastDate = new Date(newValue)
                  setDayFilterValue(lastDate)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            ) : (
              // Year Picker
              <StaticDatePicker
                id={"YearPicker"}
                key={"YearPicker"}
                displayStaticWrapperAs="desktop"
                openTo={"year"}
                views={["year"]}
                value={dayFilterValue}
                showToolbar={true}
                ToolbarComponent={ToolbarDay}
                minDate={new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)}
                maxDate={new Date().setUTCFullYear(new Date().getUTCFullYear() + 1)}
                autoFocus={true}
                onChange={(newValue) => {
                  setDayFilterValue(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          </LocalizationProvider>

          {/* Bottom Buttons Container */}
          <Box className={"bottomButtonContainer"}>
            <Typography
              className={`me-4`}
              sx={inlineStyles.bottomButtons}
              onClick={handleCloseDay}
              variant="caption5">
              Cancel
            </Typography>
            <Typography
              className={`me-3 pe-1`}
              sx={inlineStyles.bottomButtons}
              variant="caption5"
              onClick={resetDate}>
              Reset
            </Typography>
            <Typography
              className={`me-3 pe-1`}
              sx={inlineStyles.bottomButtons}
              variant="caption5"
              onClick={okayHandlingDay}>
              OK
            </Typography>
          </Box>
        </div>
      ) : null}
      {data?.isCheckBox ? (
        <CheckBoxSelect
          label={data?.title}
          menuItems={data.menu}
          value={selectedValue}
          onChange={(newValue) => handleDropdownChange(newValue)}
          style={{ width: "auto", margin: "4px 16px 16px" }}
        />
      ) : null}
      {data?.isMetrics && (
        <FilterCheckBoxSelect
          label={data?.title}
          menuItems={data.menu}
          value={selectedValue}
          onChange={(newValue) => handleDropdownChange(newValue, "metrics")}
          style={{ width: "auto", margin: "4px 16px 16px" }}
        />
      )}
    </Fragment>
  )
}

export default FilterItem

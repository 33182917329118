import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import AppTextField from "components/StyledComponents/AppTextField"
import { Col } from "react-bootstrap"
import AppDatePicker from "components/AppDatePicker"
import AppButton from "components/StyledComponents/AppButton"
import { Typography, List, Box } from "@mui/material"
import PhoneNumberField from "components/PhoneNumberField"
import { useDispatch, useSelector } from "react-redux"
import {
  getNewRestaurantListAction,
  getRoleUsersListAction,
  getUserMainListAction
} from "redux/superAdmin/actions"
import { useTheme } from "@mui/system"
import AppSelect from "components/StyledComponents/AppSelect"

const AdminUserForm = ({ formik }) => {
  const theme = useTheme()
  const { userRole } = useSelector((store) => store?.superAdmin ?? [])
  const [roleList, setRoleList] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserMainListAction())
    dispatch(getRoleUsersListAction())
    dispatch(getNewRestaurantListAction())
  }, [])

  useEffect(() => {
    if (userRole?.length) {
      const newUserList = userRole.slice(1)
      setRoleList(newUserList)
    }
  }, [userRole])

  return (
    <>
      <Container fluid className="mt-2">
        <Row></Row>
        <Row className="mt-5">
          <Col lg="4">
            <AppSelect
              id="role"
              name="role"
              menuItems={roleList}
              label="Role"
              fullWidth
              isRequired={true}
              value={formik.values?.role}
              onChange={formik.handleChange}
              error={formik.errors.role}
              touched={formik.touched.role}
            />
          </Col>
          <Col lg="4">
            <AppTextField
              id="fullName"
              name="fullName"
              fullWidth
              type="text"
              label="Full Name"
              isRequired={true}
              isUpperCase={true}
              placeholder="Enter Full Name"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Col>
          <Col lg="4">
            <AppTextField
              id="email"
              name="email"
              type="text"
              fullWidth
              label="Email"
              isRequired={true}
              isUpperCase={true}
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <List sx={{ padding: 0 }}>
              <Box>
                <Typography variant="body1Regular">Contact Number</Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: "5px" }} className="phoneContainer">
                <Col lg="12" className="phoneContainerBox">
                  <PhoneNumberField
                    id="contactNumber"
                    name="contactNumber"
                    country={"us"}
                    countryCodeEditable={false}
                    value={"+1" + formik.values.contactNumber}
                    onChange={(val, countryData) => {
                      const localNumber = val.replace(`${countryData.dialCode}`, "")
                      formik.setFieldValue("contactNumber", localNumber)
                    }}
                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                    helperText={
                      formik.touched.contactNumber && formik.errors.contactNumber
                    }></PhoneNumberField>
                </Col>
              </Box>
            </List>
          </Col>
          <Col lg="4">
            <AppTextField
              id="address"
              name="address"
              fullWidth
              type="text"
              label="Address"
              placeholder="Enter Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Col>
          <Col lg="4">
            <List sx={{ padding: 0 }}>
              <Box>
                <Typography variant="body1Regular">Date Of Hire</Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                <Col lg="12" className="dateFieldContainer">
                  <AppDatePicker
                    views={["year", "month", "day"]}
                    inputFormat="dd MMM yyyy "
                    disableMaskedInput
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                    key={"hireDate"}
                    value={formik.values.hireDate}
                    minDate={new Date().setFullYear(new Date().getFullYear() - 10)}
                    onChange={(val) => formik.setFieldValue("hireDate", val)}
                    error={formik.errors.hireDate}
                    touched={formik.touched.hireDate}
                  />
                </Col>
              </Box>
            </List>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12">
            <AppButton
              onClick={formik.handleSubmit}
              style={{ padding: "14px 40px", backgroundColor: theme.palette.secondary.main }}>
              Save User
            </AppButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminUserForm

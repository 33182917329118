import * as React from "react"
import { styled } from "@mui/material/styles"
import { makeStyles } from "@material-ui/core"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Logout from "../../assets/icons/logout.svg"
import avatarImg from "../../assets/images/avatarUser.svg"
import "./privateLayout.scss"
import { useHistory } from "react-router-dom"

import { FiltersData } from "assets/json/Filters"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { Fragment } from "react"

import FilterItem from "components/PrivateLayoutFilters/FilterItem"
import { userActions } from "redux/user/user"
import { Grid, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
// import DMVLogo from "../../assets/icons/DMV_Logo.svg"
import VantaMenuLogo from "assets/icons/VantaMenuLogo.svg"
import { revertAllLnD } from "redux/L&D/actions"
import { revertAllMarketing } from "redux/marketing/actions"
import { revertAllGM } from "redux/GM/actions"
import { revertAllDMV } from "redux/DMV/actions"
import { formatDate } from "helpers/functions"
import { revertAllSuperAdmin } from "redux/superAdmin/actions"
import SwapIcon from "@mui/icons-material/SwapHorizRounded"
import OnboardingBg from "assets/images/ScarpettaRoom.jpg"
import backgroundLogin from "assets/images/backgrounds/loginText.png"

const drawerWidth = 240

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
  flexGrow: 1,
  padding: "24px",
  backgroundColor: theme.palette.background.main,
  color: theme.palette.text.primary,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `calc(100vw - 250px)`,
  marginLeft: 0
}))

const useStyles = makeStyles(() => ({
  leftBannerContainer: {
    position: "relative",
    backgroundImage: `url(${OnboardingBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center"
  },
  rightBannerContainer: {
    backgroundImage: `url(${backgroundLogin})`,
    mixBlendMode: "screen",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }
}))

export default function PrivateLayout({ children }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const role = useSelector((res) => res.user?.userData?.data)
  const [open] = React.useState(true)
  const isAuth = location.pathname.includes("auth")
  const classes = useStyles()
  const isDmvPath = window?.location?.pathname?.match(/dmv\/.*/)

  const [filterItems] = React.useState(
    FiltersData.find((el) => window.location.pathname.endsWith(el.path))?.filters || []
  )

  const { full_name, rest_name } = useSelector((store) => store.user?.userData)
  const { image_url } = useSelector((store) => store.user?.userWithId?.data ?? "")
  const { userRestaurant, restaurantList } = useSelector((store) => store.user)

  function dispatchLogoutState() {
    dispatch(revertAllLnD())
    dispatch(revertAllMarketing())
    dispatch(revertAllGM())
    dispatch(revertAllDMV())
    dispatch(revertAllSuperAdmin())
    dispatch(userActions.setUserData({}))
    dispatch(userActions.setUserWithData({}))
    dispatch(appActions.setIsLoggedInData(false))
    dispatch(
      appActions.setFilters({
        date: formatDate(new Date())
      })
    )
    dispatch(appActions.setRestaurantList([]))
    dispatch(appActions.setUserTypeList([]))
    dispatch(userActions.setUserRestaurantList([]))
    dispatch(userActions.setUserRestaurant({}))
    dispatch(appActions.setIsMultiRestaurant(false))
  }

  const handleLogOut = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    dispatchLogoutState()
    if (role?.name === "DMV") {
      history.push("/dmv/auth/login")
    } else {
      history.push("/auth/login")
    }
  }

  const handleRestaurant = () => {
    history.push("/auth/choose-restaurant")
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      {isAuth ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={5} lg={6} className={classes.leftBannerContainer}></Grid>
              <Grid item xs={8} md={7} lg={6} className={classes.rightBannerContainer}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: `${theme.palette.drawer.main}`,
                borderRight: "1px solid #212121"
              }
            }}
            variant="persistent"
            anchor="left"
            open={open}>
            <div className="logo">
              {history?.location?.pathname?.includes("DMV") ? (
                <picture>
                  <source media="(min-width:1500px)" src="../assets/icons/DMV_Logo.svg"></source>
                  <img src={VantaMenuLogo} onClick={() => history.push("/dmv/dashboard")} />
                </picture>
              ) : (
                <picture>
                  <picture>
                    <source media="(min-width:1500px)" src="../assets/icons/DMV_Logo.svg"></source>
                    <img
                      src={VantaMenuLogo}
                      onClick={() => window.location.replace("/marketing/dashboard")}
                    />
                  </picture>
                </picture>
              )}
            </div>
            {Object.keys(userRestaurant).length !== 0 && !isDmvPath && (
              <div className="px-2 restaurant">
                <div className="restaurantLogo">
                  <picture>
                    <source media="(min-width:1500px)" src="../assets/icons/DMV_Logo.svg"></source>
                    <img src={userRestaurant?.icon || VantaMenuLogo} style={{ height: "35px" }} />
                  </picture>
                </div>
                <div className="mt-1 restaurant">
                  <Typography variant="caption5" color={theme.palette.secondary.main}>
                    {userRestaurant?.name}
                  </Typography>
                  <Typography
                    variant="tag1"
                    sx={{ fontSize: 11 }}
                    color={theme.palette.text.secondary}>
                    {userRestaurant?.location}
                  </Typography>
                </div>
              </div>
            )}

            {/* To be replaced with dynamic Filters */}
            {filterItems?.length ? (
              <div className="mt-2 filterContainer">
                {filterItems.map((filter, index) => {
                  return <FilterItem key={index} data={filter} />
                })}
              </div>
            ) : null}
            {/* Footer */}
            <div className="footer">
              <div className="footer__grid">
                <div className="avatar">
                  <div className="avatar__img">
                    <Avatar
                      alt="Remy Sharp"
                      src={image_url ? image_url : avatarImg}
                      sx={{ width: 36, height: 36 }}
                    />
                  </div>
                  <div>
                    <div>
                      <Typography variant="body2Regular">{full_name}</Typography>
                    </div>
                    <div>
                      <Typography variant="body2Regular">{rest_name}</Typography>
                    </div>
                  </div>
                </div>
                {!isDmvPath && restaurantList?.length > 1 && (
                  <div className="avatar">
                    <div className="avatar__img">
                      <SwapIcon sx={{ color: "#A4A2A1" }} />
                    </div>
                    <div>
                      <Typography
                        onClick={handleRestaurant}
                        variant="body2Regular"
                        sx={{ opacity: 0.6, cursor: "pointer" }}>
                        Switch Restaurant
                      </Typography>
                    </div>
                  </div>
                )}

                <button className="action">
                  <img className="me-3" src={Logout} />
                  <Typography onClick={handleLogOut} variant="body1Regular" sx={{ opacity: 0.6 }}>
                    Logout
                  </Typography>
                </button>
              </div>
            </div>
          </Drawer>
          <Main open={open}>{children}</Main>
        </Box>
      )}
    </Fragment>
  )
}

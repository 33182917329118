import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useRef, useState } from "react"
import AddQuizForm from "./addQuizForm"
import { validationSchemaQuiz } from "helpers/validationSchema"
import { Typography } from "@material-ui/core"
import { useTheme } from "@emotion/react"
import { useDispatch } from "react-redux"
import { createQuizAction } from "redux/DMV/actions/user"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDate } from "helpers/functions"

const AddQuiz = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const isDraft = useRef(false)

  const initialFormValue = {
    quizName: "",
    restaurants: ["All"],
    category: "",
    start_date: new Date(),
    end_date: new Date(),
    timeLimit: ""
  }

  const handleSaveAsDraft = (formik) => {
    isDraft.current = true
    formik.handleSubmit()
  }

  const handleSubmit = (values) => {
    let RestaurantList = values.restaurants
    const valueLength = RestaurantList.length
    let updatedOptions = []

    if (RestaurantList?.[valueLength - 1] === "All") {
      updatedOptions = RestaurantList.filter((value) => value === "All")
    } else if (RestaurantList?.[0] === "All" && valueLength > 1) {
      updatedOptions = RestaurantList.filter((value) => value !== "All")
    } else {
      updatedOptions = RestaurantList
    }

    const payload = {
      name: values?.quizName,
      start_date: formatDate(values?.start_date),
      end_date: formatDate(values?.end_date),
      status: "DRAFT",
      category: values?.category,
      duration: values.timeLimit,
      restaurants: updatedOptions.length && updatedOptions[0] !== "All" ? updatedOptions : [],
      all_restaurants: updatedOptions?.length === 1 && updatedOptions?.[0] === "All" ? true : false
    }
    !isDraft.current
      ? history.replace("/dmv/dashboard/quiz/question-bank")
      : history.replace("/dmv/dashboard/quiz")
    dispatch(createQuizAction(payload))
  }
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaQuiz}
          initialValues={initialFormValue}>
          {(formik) => (
            <>
              <Grid item xs={12}>
                <Header title="DMV Quiz" breadCrumbs={breadCrumbs}>
                  <Typography
                    variant="body1Regular"
                    onClick={() => handleSaveAsDraft(formik)}
                    style={{
                      color: theme.palette.white.main,
                      textDecoration: "underline",
                      margin: "13px 50px",
                      cursor: "pointer",
                      textUnderlineOffset: "2px"
                    }}>
                    Save Draft
                  </Typography>
                </Header>
              </Grid>
              <AddQuizForm formik={formik}></AddQuizForm>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default AddQuiz

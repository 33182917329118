import { appActions } from "redux/app/slice/app"
import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import slice from "../slice/user"
import { toast } from "react-toastify"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* getUserTypeList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/role/`)
    if (data.success) {
      yield put(appActions.setUserTypeList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getFrontlineUsersList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const {
      page,
      search,
      job_class,
      status,
      restaurant,
      hire_date,
      orderBy,
      user_type,
      raffle_access,
      usersLoginStatus
    } = action.payload

    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    if (job_class && job_class !== "All") {
      params += `&job_class=${job_class}`
    }
    if (status && status !== "All") {
      params += `&status=${status}`
    }
    if (restaurant && restaurant !== "All") {
      params += `&restaurant=${restaurant}`
    }
    if (hire_date) {
      params += `&hire_date=${hire_date}`
    }
    if (orderBy) {
      params += `&order_by=${orderBy}`
    }
    if (user_type) {
      params += `&user_type=${user_type}`
    }
    if (raffle_access && raffle_access !== "All") {
      params += `&raffle_access=${raffle_access}`
    }
    if (usersLoginStatus) {
      params += `&user_login_status=${usersLoginStatus}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setFrontlineUserList({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
      yield put(slice.actions.setTotalUserCount(data?.data?.count))
      yield put(slice.actions.setTotalActiveUser(data?.data?.active_user_count))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getFrontlineUsersData() {
  try {
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/super-admin/${id}/`)
    if (data.success) {
      yield put(slice.actions.setFrontlineUserData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* EditFrontlineUsersData(action) {
  try {
    const { payload, onClose } = action.payload
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.patch, `${baseUrl}/super-admin/${id}/`, payload)
    if (data.success) {
      toast.success("Update Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
    onClose()
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadFrontlineUser(action) {
  try {
    const { payload, onClose, user_type } = action.payload
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/`, payload)

    if (data.success) {
      toast.success("User has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      onClose()
      let params = `page=1&order_by=full_name`
      if (user_type && user_type !== "") {
        params += `&user_type=${user_type}`
      }
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)
      if (newData.success) {
        yield put(
          slice.actions.setFrontlineUserList({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteUser(action) {
  try {
    const { user_type, page } = action.payload
    yield put(appActions.setIsLoading(true))
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    const { data } = yield call(axios.delete, `${baseUrl}/user/${id}/`)

    let params = `page=${page}&order_by=full_name`
    if (user_type === "ADMIN") {
      params += "&user_type=ADMIN"
    }
    const { data: newData } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setFrontlineUserList({
          totalPages: newData?.data?.pages,
          data: newData?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateUserStatus(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    const { activate, source, user_type, currentPage } = action.payload
    const payload = { activate }
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/${id}/activate/`, payload)

    if (data.success) {
      if (source === "view") {
        const { data: user } = yield call(axios.get, `${baseUrl}/super-admin/${id}/`)
        if (user.success) {
          yield put(slice.actions.setFrontlineUserData(user?.data || {}))
        }
      } else {
        let params = `page=${currentPage}&order_by=full_name`
        if (user_type === "ADMIN") {
          params += "&user_type=ADMIN"
        }
        const { data: newData } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)

        if (newData?.success) {
          yield put(
            slice.actions.setFrontlineUserList({
              totalPages: newData?.data?.pages,
              data: newData?.data?.results || []
            })
          )
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadFrontlineBulkUser(action) {
  try {
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/super-admin/bulk-create/frontline`,
      {
        file: action.payload
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&order_by=full_name`
      const { data: frontlineUser } = yield call(
        axios.get,
        `${baseUrl}/super-admin/users?${params}`
      )
      if (frontlineUser.success)
        yield put(
          slice.actions.setFrontlineUserList({
            totalPages: frontlineUser?.data?.pages,
            data: frontlineUser?.data.results || []
          })
        )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadFrontlineBulkUserAdmin(action) {
  try {
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/super-admin/bulk-create/admin`,
      {
        file: action.payload
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })

      let params = `page=1&ordering=-hire_date`
      const { data: userAdmin } = yield call(axios.get, `${baseUrl}/user/?${params}`)
      if (userAdmin.success) {
        yield put(
          slice.actions.setAdminUserList({
            totalPages: userAdmin?.data?.pages,
            data: userAdmin?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getAdminUsersList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, search, role, status, restaurant, orderBy } = action.payload
    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    if (role && role !== "All") {
      params += `&role=${role}`
    }
    if (status && status !== "all") {
      params += `&status=${status}`
    }
    if (restaurant && restaurant !== "All") {
      params += `&restaurant=${restaurant}`
    }
    // if (hire_date) {
    //   params += `&hire_date=${hire_date}`
    // }
    if (orderBy) {
      params += `&ordering=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/user/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setAdminUserList({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadAdminUser(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/user/`, action.payload)

    if (data.success) {
      toast.success("User has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getUserMainList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/user-type`)
    if (data.success) {
      yield put(appActions.setAdminUserList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getRoleUsersList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/role/`)
    if (data.success) {
      yield put(appActions.setRolesUserList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getNewRestaurantList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/super-admin/restaurant/`)
    if (data.success) {
      yield put(appActions.setNewRestaurantList(data.data.results || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getAdminUsersData() {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/user/${id}/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setAdminUserData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditAdminUsersData(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.patch, `${baseUrl}/user/${id}/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data } = yield call(axios.get, `${baseUrl}/user/?page=1&ordering=-hire_date`)
      if (data.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setAdminUserList({
            totalPages: data?.data?.pages,
            data: data?.data.results || []
          })
        )
      }
      toast.success("Update Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateAdminUserStatus(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    const payload = { activate: action.payload }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/${id}/activate/`, payload)

    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: user } = yield call(axios.get, `${baseUrl}/user/${id}/`)
      if (user.success) {
        yield put(appActions.setIsLoading(false))
        yield put(slice.actions.setAdminUserData(user?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getDashboardStats() {
  try {
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/super-admin/user-count/`)
    if (data.success) {
      yield put(slice.actions.setDashboardStats(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getAppVersionsList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, search } = action.payload
    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/version/logs/?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setVersionListData({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addVersionItems(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/version/logs/`, action.payload)
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
      if (newData.success) {
        yield put(
          slice.actions.setVersionListData({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVersionData() {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/version/logs/${id}`)
    if (data.success) {
      yield put(slice.actions.setVersiondata(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateVersionItem(action) {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/version/logs/${id}`, action.payload)
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
      if (newData.success) {
        yield put(
          slice.actions.setVersionListData({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteVersionItem() {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/version/logs/${id}`)
    const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
    if (newData.success) {
      yield put(
        slice.actions.setVersionListData({
          totalPages: newData?.data?.pages,
          data: newData?.data.results || []
        })
      )
      yield put(slice.actions.setVersionID(null))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/notifications/`, action.payload)
    if (data.success) {
      toast.success("Notification has been added successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordering=-scheduled_date`
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getNotificationList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, orderBy } = action.payload
    let params = `page=${page}`
    if (orderBy) {
      params += `&ordering=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setNotificationList({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, id } = action.payload
    yield call(axios.delete, `${baseUrl}/notifications/${id}`)
    let params = `page=${page}&ordering=-scheduled_date`
    const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
    if (newData.success) {
      yield put(
        slice.actions.setNotificationList({
          totalPages: newData?.data?.pages,
          data: newData?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* sendNotification(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/notifications/trigger/`, action.payload)
    if (data.success) {
      toast.success("Notification has been sent", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordering=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* EditNotificationData(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
    yield put(appActions.setIsLoading(true))
    const { page } = action.payload
    const { data } = yield call(axios.patch, `${baseUrl}/notifications/${id}/`, action.payload)
    if (data.success) {
      toast.success("Update Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=${page}&ordering=-scheduled_date`
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getNotifications() {
  const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/notifications/${id}`)
    if (data.success) {
      yield put(slice.actions.setNotificationData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editNotification(action) {
  const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
  try {
    const { data } = yield call(axios.patch, `${baseUrl}/notifications/${id}`, action.payload)
    if (data.success) {
      toast.success("Notification has been updated", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordering=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      yield put(slice.actions.setNotificationID(null))
      if (newData.success) {
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadNotificationImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setNotificationImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGiftCardList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, giftCard_type } = action.payload

    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/gift-card/?page=${page}${
        giftCard_type && giftCard_type !== "All" ? `&card_type=${giftCard_type}` : ""
      }`
    )
    if (data.success) {
      yield put(
        slice.actions.setGiftCardList({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGiftCard() {
  const id = yield select((store) => store?.superAdmin?.giftCards?.id ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/${id}/gift-card`)
    if (data.success) {
      yield put(slice.actions.setGiftCardData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addGiftCard(action) {
  try {
    const { payload, onClose } = action.payload
    const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
    let params = `page=1`
    if (giftCard_type && giftCard_type !== "All") {
      params += `&card_type=${giftCard_type}`
    }
    const { data } = yield call(axios.post, `${baseUrl}/frontline/gift-card/`, payload)
    if (data.success) {
      onClose()
      toast.success("Prize has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
      if (newData.success) {
        yield put(
          slice.actions.setGiftCardList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteGiftCard(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
    const { id, page } = action.payload
    let params = `page=${page}`
    if (giftCard_type && giftCard_type !== "All") {
      params = `&card_type=${giftCard_type}`
    }
    yield call(axios.delete, `${baseUrl}/frontline/${id}/gift-card`)
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
    if (newData.success) {
      yield put(
        slice.actions.setGiftCardList({
          totalPages: newData?.data?.pages,
          data: newData?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* EditGiftCard(action) {
  const id = yield select((store) => store?.superAdmin?.giftCards?.id ?? "")
  const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
  let params = `page=1`
  if (giftCard_type && giftCard_type !== "All") {
    params = `&card_type=${giftCard_type}`
  }
  try {
    const { payload, onClose } = action.payload
    const { data } = yield call(axios.patch, `${baseUrl}/frontline/${id}/gift-card`, payload)
    if (data.success) {
      onClose()
      toast.success("Prize has been edited", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
      yield put(slice.actions.setGiftCardID(null))
      if (newData.success) {
        yield put(
          slice.actions.setGiftCardList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadGiftCardImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setGiftCardImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGamificationData() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/gamification-cms/`)
    if (data.success) {
      yield put(slice.actions.setGamificationData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* EditGamification(action) {
  try {
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/gamification-cms/`,
      action?.payload
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gamification-cms/`)
      if (newData.success) {
        yield put(slice.actions.setGamificationData(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getEventData(action) {
  try {
    const { orderBy } = action.payload
    let params
    if (orderBy) {
      params = `sort_by=${orderBy}`
    }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/cms/event/?${params}`)
    if (data.success) {
      yield put(slice.actions.setEventList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addEvent(action) {
  const isUpdating = yield select((store) => store.app?.isUpdating)
  if (!isUpdating) {
    yield put(appActions.setIsLoading(true))
    try {
      const { payload, onClose } = action.payload
      const { data } = yield call(axios.post, `${baseUrl}/frontline/cms/event/`, payload)
      yield put(appActions.setIsLoading(true))
      if (data.success) {
        onClose()
        toast.success("Event has been successfully added", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        })
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/cms/event/?sort_by=-start_date`
        )
        if (newData.success) {
          yield put(slice.actions.setEventList(newData.data))
        }
      }
    } catch (e) {
      console.log(e)
    }
    yield put(appActions.setIsLoading(false))
  }
}

export function* deleteEvent(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { id } = action.payload
    yield call(axios.delete, `${baseUrl}/frontline/event/${id}`)
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/cms/event/?sort_by=-start_date`
    )
    if (newData.success) {
      yield put(slice.actions.setEventList(newData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getEventDetails() {
  const id = yield select((store) => store?.superAdmin?.eventID ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/event/${id}`)
    if (data.success) {
      yield put(slice.actions.setEventDetails(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editEvent(action) {
  const isUpdating = yield select((store) => store.app?.isUpdating)
  if (!isUpdating) {
    yield put(appActions.setIsLoading(true))
    try {
      const { payload, onClose } = action.payload
      const id = yield select((store) => store?.superAdmin?.eventID ?? "")
      const { data } = yield call(axios.patch, `${baseUrl}/frontline/event/${id}`, payload)
      if (data.success) {
        onClose()
        toast.success("Event has been successfully updated", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        })
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/cms/event/?sort_by=-start_date`
        )
        if (newData.success) {
          yield put(slice.actions.setEventList(newData.data))
        }
      }
    } catch (e) {
      console.log(e)
    }
    yield put(appActions.setIsLoading(false))
  }
}

export function* getMenuImage() {
  yield put(appActions.setIsLoading(true))
  try {
    const { data } = yield call(axios.get, `${baseUrl}/frontline/menu/categories`)
    if (data.success) {
      yield put(slice.actions.setMenuImageList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addMenuImage(action) {
  const isUpdating = yield select((store) => store.app?.isUpdating)
  if (!isUpdating) {
    yield put(appActions.setIsLoading(true))
    try {
      const { payload, onClose } = action.payload
      const { data } = yield call(axios.post, `${baseUrl}/frontline/menu/categories`, payload)
      if (data.success) {
        onClose()
        const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/menu/categories`)
        if (newData.success) {
          yield put(slice.actions.setMenuImageList(newData.data))
        }
      }
    } catch (e) {
      console.log(e)
    }
    yield put(appActions.setIsLoading(false))
  }
}

export function* uploadMenuImage(action) {
  yield put(appActions.setIsLoading(true))
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      yield put(
        slice.actions.setMenuImage({
          index: action.payload.index,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* downloadMenuTemplate(action) {
  const { selectedMenu, sub_category } = action.payload
  let params = `category=${selectedMenu}`
  if (selectedMenu === "Bar Menu" && sub_category && sub_category !== "") {
    params += `&sub_category=${sub_category}`
  }
  yield put(appActions.setLoadingData(true))
  try {
    const { data } = yield call(axios.get, `${baseUrl}/frontline/menu/template?${params}`)
    if (data.success) {
      yield put(slice.actions.setDownloadMenuTemplate(data.data.template_url))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDynamicMenuFields(action) {
  try {
    const { category, sub_category } = action.payload
    let params = `category=${category}`
    if (category === "Bar Menu" && sub_category && sub_category !== "") {
      params += `&sub_category=${sub_category}`
    }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/dynamic-fields/?${params}`)
    if (data.success) {
      yield put(slice.actions.setMenuFields(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* createDynamicMenuFields(action) {
  try {
    const { payload, category, onClose, barCategory } = action.payload
    let params = `category=${category}`
    if (category === "Bar Menu" && barCategory && barCategory !== "") {
      params += `&sub_category=${barCategory}`
    } else {
      params += `&sub_category=null`
    }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/dynamic-fields/`, payload)
    if (data.success) {
      onClose()
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/dynamic-fields/?${params}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuFields(newData.data))
      }
      const { data: templateDate } = yield call(
        axios.get,
        `${baseUrl}/frontline/menu/template?${params}`
      )
      if (templateDate.success) {
        yield put(slice.actions.setDownloadMenuTemplate(templateDate.data.template_url))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteMenuField(action) {
  try {
    const { id, category, barCategory } = action.payload
    let params = `category=${category}`
    if (category === "Bar Menu" && barCategory && barCategory !== "") {
      params += `&sub_category=${barCategory}`
    }
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/dynamic-fields/${id}/`)
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/dynamic-fields/?${params}`
    )
    if (newData.success) {
      yield put(slice.actions.setMenuFields(newData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editDynamicMenuFields(action) {
  try {
    const { payload, category, barCategory } = action.payload
    let params = `category=${category}`
    if (category === "Bar Menu" && barCategory && barCategory !== "") {
      params += `&sub_category=${barCategory}`
    }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/frontline/dynamic-fields/`, payload)
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/dynamic-fields/?${params}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuFields(newData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* sendInvite(action) {
  try {
    const { payload, showSuccessModal } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/user/send-invite/`, payload)
    if (data.success) {
      showSuccessModal()
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getUserRole() {
  yield put(appActions.setIsLoading(true))
  try {
    const { data } = yield call(axios.get, `${baseUrl}/user/job-class/`)
    if (data.success) {
      yield put(slice.actions.setUserRoleList(data?.data))
      let roleList = []
      if (data.data?.length > 0) {
        for (let i of data.data) {
          roleList.push({
            value: i.id,
            label: i.name
          })
        }
      }
      roleList.unshift({ label: "All", value: "All" })
      yield put(slice.actions.setUserRole(roleList))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getRoleDetails() {
  yield put(appActions.setIsLoading(true))
  try {
    const id = yield select((store) => store?.superAdmin?.userRoleId ?? "")
    const { data } = yield call(axios.get, `${baseUrl}/user/job-class/${id}/`)
    if (data.success) {
      yield put(slice.actions.setRoleDetails(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editRoleDetails(action) {
  yield put(appActions.setIsLoading(true))
  try {
    const { payload } = action.payload
    const id = yield select((store) => store?.superAdmin?.userRoleId ?? "")
    const { data } = yield call(axios.patch, `${baseUrl}/user/job-class/${id}/`, payload)
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/user/job-class/`)
      if (newData.success) {
        yield put(slice.actions.setUserRoleList(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(slice.actions.setRoleDetails(""))
  yield put(appActions.setIsLoading(false))
}

export function* addRoleDetails(action) {
  yield put(appActions.setIsLoading(true))
  try {
    const { payload } = action.payload
    const { data } = yield call(axios.post, `${baseUrl}/user/job-class/`, payload)
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/user/job-class/`)
      if (newData.success) {
        yield put(slice.actions.setUserRoleList(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(slice.actions.setRoleDetails(""))
  yield put(appActions.setIsLoading(false))
}

export function* deleteRoleDetails() {
  yield put(appActions.setIsLoading(true))
  try {
    const id = yield select((store) => store?.superAdmin?.userRoleId ?? "")
    const { data } = yield call(axios.delete, `${baseUrl}/user/job-class/${id}/`)
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/user/job-class/`)
      if (newData.success) {
        yield put(slice.actions.setUserRoleList(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(slice.actions.setRoleDetails(""))
  yield put(appActions.setIsLoading(false))
}

import { Box, Dialog, DialogContent, Grid, IconButton, styled, Typography } from "@mui/material"
import { Formik } from "formik"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"
import AppSelect from "components/StyledComponents/AppSelect"
import AppButton from "components/StyledComponents/AppButton"
import styles from "./analytics.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getExportDataAction } from "redux/DMV/actions/user"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const ExportData = ({ onClose = () => {}, activeTab = "", analyticsType = "trend-analytics" }) => {
  const { restaurant, period } = useSelector((state) => state?.app?.filters || {})
  const dispatch = useDispatch()

  const exportOptions =
    analyticsType !== "trend-analytics"
      ? [
          { value: "excel", label: "EXCEL" },
          { value: "csv", label: "CSV" }
        ]
      : [{ value: "excel", label: "EXCEL" }]
  const handleSubmit = (values) => {
    dispatch(
      getExportDataAction({
        type: values.export,
        restaurant,
        period,
        activeTab,
        analyticsType,
        onClose
      })
    )
  }
  return (
    <StyledDialog open={true} maxWidth="md">
      <DialogContent id="exportDataDialog" className={styles.exportDialogBox}>
        <Formik
          initialValues={{ export: "excel" }}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {(formik) => (
            <React.Fragment>
              <Box className="d-flex justify-content-center position-relative">
                <Typography variant="h2" className="my-3">
                  Export data
                </Typography>
                <Box className={styles.closeButton}>
                  <span role="button" onClick={onClose}>
                    <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                      <CloseIcon />
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <Box px={2} mt={2}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={11}>
                    <AppSelect
                      id="export"
                      name="export"
                      menuItems={exportOptions}
                      label="Export as"
                      isRequired={true}
                      value={formik.values.export}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <Box className="d-flex justify-content-center gap-5 mt-3">
                  <AppButton className="mt-3 px-5" variant="text" onClick={onClose}>
                    Cancel
                  </AppButton>
                  <AppButton
                    className="mt-3 px-5"
                    variant="outlined"
                    disabled={formik.values.export ? false : true}
                    onClick={formik.handleSubmit}>
                    Export
                  </AppButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default ExportData

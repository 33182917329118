import { Box, Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import FrontLineUserListTable from "./frontLineUserListTable/index"
import { useHistory } from "react-router-dom"
import { getFrontlineUsersListAction, sendInviteAction } from "redux/superAdmin/actions"
import { useDispatch, useSelector } from "react-redux"
import UploadBulkUserModal from "./components/UploadBulkUserModal"
import { superAdminAction } from "redux/superAdmin/slice/user"
import { appActions } from "redux/app/slice/app"
import { toTitleCase } from "helpers/functions"
import SuccessModal from "./components/SuccessModal"
import { useDebounce } from "hooks/utils"
import SearchComponent from "components/SearchComponent"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const FrontlineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [exceptUsers, setExceptUsers] = useState([])
  const [openModal, setopenModal] = useState(false)
  const [sendInvite, setSendInvite] = useState(false)

  const { frontlineUserList } = useSelector((store) => store?.superAdmin?.frontlineUser || [])
  const { totalActiveUser } = useSelector((store) => store?.superAdmin || 0)
  const {
    searchField,
    employeeClass,
    userStatus,
    date,
    restaurant,
    orderBy,
    raffleAccess,
    usersLoginStatus
  } = useSelector((store) => store?.app?.filters || "")
  const debouncedSearchTerm = useDebounce(searchField, 500)
  const handleClose = () => {
    setModalOpen(false)
  }

  const handleSendInvite = () => {
    if (selectedUsers?.length || exceptUsers?.length || selectAll === true) {
      const payload = {
        send_to_all: selectAll,
        selected_ids: selectedUsers,
        exclude_ids: exceptUsers
      }
      const handleSuccessModal = () => {
        setopenModal(true)
        setSelectAll(false)
        setSelectedUsers([])
        setExceptUsers([])
      }
      dispatch(sendInviteAction({ payload, showSuccessModal: handleSuccessModal }))
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(superAdminAction.setFrontlineUserData({}))
    dispatch(appActions.initFilters())
    dispatch(appActions.setInitDate(""))
    dispatch(appActions.setOrderBy("full_name"))
  }, [])

  useEffect(() => {
    dispatch(
      getFrontlineUsersListAction({
        page: currentPage,
        search: debouncedSearchTerm,
        job_class: employeeClass,
        status: toTitleCase(userStatus),
        restaurant: restaurant,
        hire_date: date,
        orderBy: orderBy,
        raffle_access: raffleAccess,
        usersLoginStatus: usersLoginStatus
      })
    )
  }, [
    currentPage,
    debouncedSearchTerm,
    employeeClass,
    userStatus,
    restaurant,
    date,
    orderBy,
    raffleAccess,
    usersLoginStatus
  ])

  function handleSend() {
    setSendInvite(true)
  }

  const users =
    selectedUsers && selectedUsers?.length > 0
      ? selectedUsers.length > 1
        ? `${selectedUsers.length} users`
        : "this user"
      : selectAll
      ? exceptUsers?.length === 0
        ? "all active users"
        : `${totalActiveUser - exceptUsers.length} users`
      : "this user"

  useEffect(() => {
    setCurrentPage(1)
  }, [
    debouncedSearchTerm,
    employeeClass,
    userStatus,
    restaurant,
    date,
    orderBy,
    raffleAccess,
    usersLoginStatus
  ])

  useEffect(() => {}, [frontlineUserList?.data])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to send invite to ${users}?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        btnText="Send"
        open={sendInvite}
        onClose={() => setSendInvite(false)}
        onDelete={() => handleSendInvite()}
      />
      <UploadBulkUserModal open={modalOpen} onClose={handleClose}></UploadBulkUserModal>
      <Grid container>
        <Grid item xs={12}>
          <Header title="User List" breadCrumbs={breadCrumbs}>
            <Box
              sx={{
                marginRight: "20px"
              }}>
              <SearchComponent />
            </Box>
            {(selectedUsers?.length || exceptUsers?.length || selectAll === true) && (
              <AppButton
                onClick={() => handleSend()}
                sx={{ padding: "10px 37px", marginRight: "20px" }}>
                Send Invite
              </AppButton>
            )}
            <AppButton
              onClick={() => setModalOpen(true)}
              sx={{
                cursor: "pointer",
                padding: "10px 20px",
                marginRight: "20px"
              }}>
              + Add Bulk User
            </AppButton>
            <AppButton
              onClick={() =>
                history.push("/super-admin/dashboard/frontline-user-list/add-frontline-user")
              }
              sx={{ padding: "10px 20px" }}>
              + Add New User
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col lg="12">
            <FrontLineUserListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={frontlineUserList?.totalPages}
              rows={frontlineUserList?.data}
              columns={columns}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              exceptUsers={exceptUsers}
              setExceptUsers={setExceptUsers}
            />
          </Col>
        </Row>
      </Container>
      <SuccessModal open={openModal} onClose={() => setopenModal(false)} />
    </>
  )
}

export default FrontlineUser
const columns = [
  {
    id: "select_all",
    label: "",
    minWidth: 70,
    align: "center"
  },
  {
    id: "id",
    label: "S.No",
    minWidth: 100,
    align: "center"
  },
  {
    id: "Name",
    label: "Name",
    isSorting: true,
    sortKey: "full_name",
    minWidth: 200
  },
  {
    id: "Email",
    label: "Email",
    align: "center",
    minWidth: 170
  },
  {
    id: "Role",
    label: "Role ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Invitation email",
    label: "Invitation email",
    align: "center",
    minWidth: 170
  },
  {
    id: "Invitation email Opened",
    label: "Invitation email Opened",
    align: "center",
    minWidth: 200
  },
  {
    id: "User Logged in",
    label: "User Logged in",
    align: "center",
    minWidth: 170
  },
  {
    id: "Raffle Access",
    label: "Raffle Access",
    align: "center",
    minWidth: 135
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 170
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 100
  }
]

import React, { useEffect, useState } from "react"
import { Dialog, DialogContent, IconButton, styled, Typography, Box } from "@mui/material"
import ImageUploadPopup from "pages/private/learning_development/trivia-contest/components/ImageUploadPopup"
import { useDispatch, useSelector } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import CloseIcon from "@mui/icons-material/Close"
import DeleteImageIcon from "assets/icons/closeIcon.svg"
import { Formik } from "formik"
import ImageIcon from "assets/icons/ImageIcon.svg"
import AppTextField from "components/StyledComponents/AppTextField"
import DraggableOptions from "components/DraggableOptions"
import AppButton from "components/StyledComponents/AppButton"
import styles from "./EditQuestion.module.scss"
import { validationSchemaQuizQuestion } from "helpers/validationSchema"
import { editSuggestedQuestionAction, uploadSuggestedQuestionImageAction } from "redux/L&D/actions"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const EditQuestion = ({ onClose = () => {}, data, onQuestionEdited = () => {} }) => {
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [question, setQuestion] = useState({})
  const dispatch = useDispatch()
  const { image, isEdited } = useSelector((store) => store?.learningDevelopment?.suggestedQuestion)

  const onImagePopupOpen = () => {
    setIsImagePopupOpen(true)
  }

  const onImagePopupClose = () => {
    setIsImagePopupOpen(false)
  }

  const onImageUpload = (newImage) => {
    let data = new FormData()
    data.append("media", newImage)
    data.append("media_type", "IMAGE")
    data.append("content_type", newImage.type)
    dispatch(uploadSuggestedQuestionImageAction(data))
  }

  const onImageDelete = () => {
    setSelectedImage(null)
    dispatch(learningDevelopmentActions.setSuggestedQuestionImage(null))
  }

  const handleSubmit = (values) => {
    const payload = {
      image: image?.id || null,
      title: values.question,
      answers: values?.optionSet?.map((option) => ({
        id: option.id,
        answer: option.name,
        is_correct: option.isCorrect
      }))
    }
    dispatch(editSuggestedQuestionAction({ questionId: data.id, data: payload }))
  }

  const initFormikData = () => {
    setQuestion({
      questionId: data.id,
      question: data?.title ?? "",
      optionSet: data?.answer?.map((option) => ({
        id: option.id,
        name: option.answer,
        isCorrect: option.is_correct
      }))
    })
    dispatch(
      learningDevelopmentActions.setSuggestedQuestionImage({
        id: data.image_id,
        url: data.image
      })
    )
  }
  useEffect(() => {
    if (isEdited) {
      onQuestionEdited()
      onClose()
    }
  }, [isEdited])

  useEffect(() => {
    initFormikData()
  }, [data])

  return (
    <StyledDialog open={true} onClose={onClose} maxWidth="lg">
      <ImageUploadPopup
        onClose={onImagePopupClose}
        open={isImagePopupOpen}
        aspectHeight={1}
        aspectWidth={1}
        {...{
          selectedImage,
          setSelectedImage,
          onImageUpload,
          onImageDelete
        }}
        {...{
          selectedImage,
          setSelectedImage,
          onImageUpload,
          onImageDelete
        }}
      />
      <DialogContent id="editQuestionDialog" className={styles.dialogBox}>
        <Formik
          initialValues={question}
          validationSchema={validationSchemaQuizQuestion}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {(formik) => (
            <React.Fragment>
              <Box className="d-flex justify-content-center position-relative">
                <Box className={styles.closeButton}>
                  <span role="button" onClick={onClose}>
                    <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                      <CloseIcon sx={{ height: "14px", width: "14px" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <Box height={40} />
              <Box sx={{ padding: "0 11px" }}>
                <Box height={19} />
                <Typography variant="h2" sx={{ fontSize: 20 }}>
                  Question
                </Typography>
                <Box height={19} />
                <Box className="d-flex">
                  <AppTextField
                    id="question"
                    name="question"
                    placeholder="Enter Question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    className="flex-grow-1 flex-shrink-1"
                    fullWidth
                    error={formik.touched.question && Boolean(formik.errors.question)}
                    helperText={formik.touched.question && formik.errors.question}
                  />
                  <img
                    role="button"
                    onClick={onImagePopupOpen}
                    src={ImageIcon}
                    className="flex-shrink-0 ms-4"
                    alt="Upload Image"
                  />
                </Box>
                {image?.url ? (
                  <Box className="mt-4 position-relative" sx={{ width: "fit-content" }}>
                    <img
                      style={{ height: 182, borderRadius: 6, width: "100%", maxWidth: "250px" }}
                      src={image.url}
                      alt="image"
                    />
                    <img
                      role="button"
                      src={DeleteImageIcon}
                      alt="delete image"
                      className="position-absolute"
                      style={{ top: 12, right: 12 }}
                      onClick={onImageDelete}
                    />
                  </Box>
                ) : null}
                <DraggableOptions
                  formik={formik}
                  getOptionValues={(values = formik.values) => values?.optionSet}
                  getOptionPath={() => `optionSet`}
                />
                <Box className="d-flex justify-content-center">
                  <AppButton className="mt-3 px-5" variant="outlined" onClick={formik.handleSubmit}>
                    Save
                  </AppButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default EditQuestion

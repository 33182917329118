import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  Backdrop,
  Menu
} from "@mui/material"
import { styled } from "@mui/system"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import TableNoRecords from "components/TableNoRecords"
import React, { useRef, useState } from "react"
import { useTheme } from "@emotion/react"
import { DMVActions } from "redux/DMV/slice/user"
import { useDispatch } from "react-redux"
import styles from "./quizTable.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import AppPagination from "components/StyledComponents/AppPagination"
import { capitalize, formatDates } from "helpers/functions"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 27
    },
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))
const QuizTable = ({ columns, quizList }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(false)
  const [quizStatus, setQuizStatus] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const history = useHistory()
  const rowId = useRef(null)
  const dispatch = useDispatch()
  const openMenu = Boolean(anchorEl)

  const onOptionsClick = (e, id, status) => {
    rowId.current = id
    e.stopPropagation()
    setQuizStatus(status)
    dispatch(DMVActions.setQuizId(rowId.current))

    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const viewQuiz = () => {
    history.push("/dmv/dashboard/quiz/view-quiz")
  }

  const editQuiz = () => {
    history.push("/dmv/dashboard/quiz/edit-quiz")
  }

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return { color: theme.palette.green.main }
      case "draft":
        return { color: theme.palette.orange.main }
      case "published":
        return { color: theme.palette.green.light }
      default:
        return { color: theme.palette.text.primary }
    }
  }

  const itemsPerPage = 12
  const totalPages = Math.ceil(quizList?.length / itemsPerPage)

  const paginatedQuizList = quizList?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  return (
    <TableContainer sx={{ maxHeight: 800 }}>
      <StyledTable stickyHeader aria-label="contest table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ minWidth: 50 }}>
              <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                #
              </Typography>
            </TableCell>
            {columns?.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {quizList?.length ? (
            <>
              {paginatedQuizList.map((quiz, index) => (
                <TableRow key={quiz.id}>
                  <TableCell align="center">
                    <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                      {currentPage * 10 - (10 - 1) + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{quiz?.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular" sx={() => getStatusStyles(quiz.status)}>
                      {capitalize(quiz?.status)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{formatDates(quiz?.start_date)}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular"> {formatDates(quiz?.end_date)}</Typography>
                  </TableCell>
                  <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                    <span
                      onClick={(e) => onOptionsClick(e, quiz.id, quiz.status)}
                      className={styles.actionBtnCtr}>
                      <MoreHorizIcon
                        sx={() => ({
                          height: "24px",
                          width: "24px",
                          cursor: "pointer"
                        })}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableNoRecords colSpan={9} label="No Quiz Found" />
          )}
        </TableBody>
      </StyledTable>
      {quizList?.length ? (
        <AppPagination
          onPageChange={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
      {openMenu && (
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: theme.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: theme.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem onClick={() => viewQuiz()}>
              <Typography variant="body1Regular">View</Typography>
            </StyledMenuItem>
            {quizStatus.toLowerCase() === "draft" && (
              <StyledMenuItem onClick={() => editQuiz()}>
                <Typography variant="body1Regular">Edit</Typography>
              </StyledMenuItem>
            )}
          </Menu>
        </Backdrop>
      )}
    </TableContainer>
  )
}

export default QuizTable
